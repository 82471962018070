import React, { useContext, useEffect, useState } from 'react'

import { Context } from '../../App'

import "../assets/css/comman/propertys.css"
import ButtonA from '../atoms/ButtonA'
import ImageUploader from './ImageUploader'
import { FETCH } from '../services/Fetch.tsx'


const PropertySection = (props) => {
    // console.log('4')
    const { editMode, setEditMode } = useContext(Context)
    const { prptId, mainImg, imgArr, prjName, prptDest, prptDsc ,server} = props
    const [prptVal, setPrptVal] = useState(props)

    const handleChange = (e) => {
        // console.log('HAndling Text CHange', e.target.name, e.target.value)
        setPrptVal(prev => {
            return ({ ...prptVal, [e.target.name]: e.target.value })
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        // console.log(prptVal)

        let formData = new FormData()
        // console.log('PRPT val',prptVal)
        formData.append("prptId",prptVal.prptId)
        formData.append("prjName",prptVal.prjName)
        formData.append("prptDest",prptVal.prptDest)
        formData.append("prptDsc",prptVal.prptDsc)
        formData.append("mainImg",prptVal.mainImg)
        for(let img of prptVal.imgArr){
            formData.append("imgArr",img)
        }
        
        // console.log('Form Data is ',formData)
        const response = await fetch('https://api.finainv.in/update-property',{
            method : 'POST',
            mode : 'cors',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body : formData
        })
        let data = await response.json()
        // console.log('Data Recieved ' , data)
        alert(data.message)
    }

    const handleDelete = async()=>{
        let {data , status} = await FETCH(
            'post',
            '/delete-property',
            prptId
        )
        alert(data.message)
    }

    useEffect(() => {
        // console.log('****---mmm', prptVal,typeof(mainImg),server+mainImg)
        setPrptVal(props)
    }, [props])

    if (!editMode) {
        return (
            <div className="property-container">
                <div className="property-main">
                    <div className="section-inner-heading property-heder-text">{prjName}</div>
                    <div className="property-text-main">
                        <div className="property-Pra-text1">
                            <div className="property-Pra-text-information">{prptDsc}</div>
                            <br />
                            <div className="property-text-address">Destination:<div className="property-text-address-span">{prptDest}</div></div>
                        </div>
                        <div className="property-Pra-text2">
                            <img src={server + mainImg} className="property-images" alt={"logo"} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className="property-pic1-main1">
                        <div className="scroll-content">
                            {imgArr.map((el, i) => {
                                return (<img className='scroll-item' src={server+el} alt={"logo"} key={i} />)
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="property-container">
                <form className="property-main" onSubmit={handleSubmit} method='post'>
                    <div className="section-inner-heading property-heder-text">
                        <input className="section-inner-heading property-heder-text" name='prjName' value={prptVal.prjName} onChange={handleChange} />
                    </div>
                    <div className="property-text-main">
                        <div className="property-Pra-text1">
                            <div className="property-Pra-text-information">
                                <textarea name='prptDsc' value={prptVal.prptDsc} onChange={handleChange} className="property-Pra-text-information" cols={60} rows={10} />
                            </div>
                            <br />
                            <div className="property-text-address">Destination:<div className="property-text-address-span">
                                <textarea name='prptDest' value={prptVal.prptDest} onChange={handleChange} className='property-text-address property-text-address-span' cols={45} rows={2} />
                            </div>
                            </div>
                        </div>
                        <div className="property-Pra-text2">
                            <ImageUploader parentClass={'property-images'}
                                val={prptVal} setVal={setPrptVal} id={999} name={'mainImg'} mw={`650px`} mh={`450px`} ele={server + mainImg}/>
                        </div>
                    </div>
                    <div className="property-pic1-main1">
                        <div className="scroll-content">
                            {imgArr.map((el, i) => {
                                return (
                                    <>
                                        <ImageUploader parentClass={'scroll-item'}
                                            val={prptVal} setVal={setPrptVal} key={i} id={i} ele={server + el} mw={`425px`} mh={`280px`} name={'imgArr'}/>
                                    </>)
                            })}
                        </div>
                    </div>
                    <ButtonA name={`Update`} ></ButtonA>
                    </form>
                    <ButtonA name={`Delete ${prptVal.prjName}`} onClick={handleDelete}></ButtonA>
            </div>
        )
    }

}

export default PropertySection