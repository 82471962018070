import React from 'react'
import invo1 from '../../assets/images/Slider1.jpg';
import '../../assets/css/comman/investment.css'
import ListItem from '../../atoms/ListItem';
import ListItem1 from '../../atoms/ListItem1';

const Investmentinfo = () => {
  return (
    <div id='investmentinfo' style={{paddingTop:`40px`}}>
      <div className='service-parent-flex-center' style={{ flexDirection: `column` }}>

        <p className="section-heading service-parent-flex-center">Investment Management</p>

        <p className="services-text-paregraph at ">Finasource Innovations provides a comprehensive array of financial services to meet diverse needs:
          With a commitment to excellence and client satisfaction, Finasource Innovations stands as a versatile partner,
          addressing a spectrum of financial needs across different sectors.</p>

        <p className="services-text-heding">Objectives of Investment Management:</p>
        <p className="services-text-paregraph at">
          The objectives of investment can vary based on individual preferences, financial goals, risk tolerance, and time horizon. Generally, investors aim to achieve a combination of the following objectives:
        </p>


        <div className="chooses-text-main">

          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`1.	Wealth Accumulation `}
            bodyMessage={`Many investors seek to grow their wealth over time by investing in assets that have the potential for capital appreciation. This can involve investing in stocks, real estate, mutual funds, or other vehicles that historically have shown the potential for long-term growth.`}
          />

          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`2.	Income Generation  `}
            bodyMessage={`Some investors prioritize generating a regular income stream from their investments. This is often the goal for retirees or those looking for passive income. Investments such as bonds, dividend-paying stocks, and real estate can provide regular income in the form of interest or dividends.`}
          />

          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`3.	Capital Preservation  `}
            bodyMessage={`Not all investors are willing to take on high levels of risk. Some prioritize the preservation of their capital over aggressive growth. Conservative investments, such as government bonds or stable value funds, may be favored by those seeking to protect their principal.`}
          />

          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`4.	Diversification `}
            bodyMessage={`Diversification is a risk management strategy where investors spread their investments across different asset classes (e.g., stocks, bonds, real estate) to reduce the impact of poor performance in any one investment. The objective is to achieve a balance between risk and return.`}
          />

          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`5.	Tax Efficiency `}
            bodyMessage={` Minimizing tax liabilities is often a consideration for investors. They may seek tax-efficient investment strategies, such as investing in tax-advantaged accounts or using strategies like tax-loss harvesting.`}
          />

          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`6.	Retirement Planning `}
            bodyMessage={`Many investors invest with the goal of building a retirement nest egg. Retirement planning involves saving and investing with the aim of creating a source of income during retirement years.`}
          />
          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`7.	Short-Term Liquidity`}
            bodyMessage={` Some investors have short-term financial goals and need access to their funds in the near future. In such cases, they may choose relatively liquid investments to ensure that funds can be accessed quickly when needed.`}
          />

          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`8.	Socially Responsible Investing `}
            bodyMessage={`Increasingly, investors are incorporating ethical, social, or environmental considerations into their investment decisions. Socially responsible investing (SRI) involves aligning investments with personal values or supporting companies that are socially and environmentally responsible.`}
          />
          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`9.	Speculation  `}
            bodyMessage={`Some investors engage in speculative investments with the goal of making quick profits. Speculative investments often involve higher risk and may not align with traditional long-term investment principles.`}
          />
        </div>
        <p className="services-text-paregraph at">
        It's important for investors to define their objectives clearly, considering factors such as risk tolerance, time horizon, and financial goals. Additionally, regularly reviewing and adjusting investment objectives as circumstances change is a prudent practice.
        </p>


        <p className="services-text-heding">Investment Opportunities in India:</p>
        <p className="services-text-paregraph at">
        India offers various investment options across different asset classes. Here are some common investment types in India:
        </p>


        <div className="chooses-text-main" style={{alignItems:'start'}}>

        <ListItem1 
        parentClass={`chooses-text-p`}
        preColHeading={`1.	Equity Investments `}
        preColHeading1={`• Stocks`}
        bodyMessage={`: Investing in shares of publicly listed companies on stock exchanges.`}
        preColHeading2={` • Equity Mutual Funds`}
        bodyMessage1={`: Pooling money with other investors to invest in a diversified portfolio of stocks managed by a professional fund manager.`}
       />

          <ListItem1
            parentClass={`chooses-text-p`}
            preColHeading={`2.	Fixed-Income Investments `}
            preColHeading1={`• Fixed Deposits (FDs)`}
            bodyMessage={`: Investing a lump sum for a fixed period at a predetermined interest rate with banks or non-banking financial companies (NBFCs).`}
            preColHeading2={`• Bonds/Debentures`}
            bodyMessage1={`: Debt securities issued by corporations or the government, providing regular interest payments.`}
          />

          <ListItem1
            parentClass={`chooses-text-p`}
            preColHeading={`3.	Mutual Funds  `}
            preColHeading1={`•	Debt Mutual Funds`}
            bodyMessage={`: Invest in fixed-income securities like government bonds, corporate bonds, and money market instruments.`}
            preColHeading2={`•	Hybrid Mutual Funds`}
            bodyMessage1={`: Combine both equity and debt instruments in the portfolio.`}
          />

          <ListItem1
            parentClass={`chooses-text-p`}
            preColHeading={`4.	Real Estate `}
            preColHeading1={`•	Real Estate Investment Trusts (REITs) `}
            bodyMessage={`: Investment in income-generating real estate assets, offering a share in the rental income and capital appreciation.`}
          />

          <ListItem1
            parentClass={`chooses-text-p`}
            preColHeading={`5.	Gold `}
            preColHeading1={`•	Gold ETFs`}
            bodyMessage={`: Exchange-traded funds tracking the price of gold, providing an indirect way to invest in physical gold.`}
            preColHeading2={`•	Sovereign Gold Bonds`}
            bodyMessage1={`: Government securities denominated in grams of gold, offering both capital appreciation and interest.`}
          />

          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`6.	Public Provident Fund (PPF)  `}
            bodyMessage={`A long-term savings scheme with tax benefits offered by the government.`}
          />
          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`7.	National Pension System (NPS)`}
            bodyMessage={` A voluntary, long-term retirement savings scheme designed to enable systematic savings.`}
          />

          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`8.	Employee Provident Fund (EPF) `}
            bodyMessage={`A retirement benefits scheme for salaried employees, with both the employee and employer contributing a fixed percentage of the salary.`}
          />
          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`9.	Sukanya Samriddhi Yojana `}
            bodyMessage={`A government-backed savings scheme for the girl child, offering a high interest rate and tax benefits.`}
          />
          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`10.	Post Office Savings Schemes`}
            bodyMessage={`Various savings schemes like Public Provident Fund (PPF), Monthly Income Scheme (MIS), and Senior Citizens Savings Scheme (SCSS) offered through post offices.`}
          />
          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`11.	National Savings Certificate (NSC) `}
            bodyMessage={`A fixed-deposit type investment with a fixed interest rate, backed by the government.`}
          />
          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`12.	Direct Equity Investment `}
            bodyMessage={`Apart from investing in stocks through mutual funds, individuals can directly buy and sell shares in the stock market.`}
          />
        </div>


        <p className="services-text-paregraph at">
        Before making any investment decisions, it is crucial to assess your financial goals, risk tolerance, and investment horizon. It's advisable to consult with a financial advisor to create a diversified and personalized investment portfolio. Additionally, staying informed about market trends and economic conditions is essential for making informed investment decisions.
        </p>

      </div>
      <div className="invo-text-main bg-section-color">

        <p className="section-heading service-parent-flex-center" style={{fontSize:`2rem`}}>How can Finasource innovations help ?</p>


        <div className="invo-box-main " >
          <div className="invo-box" >
            <img src={invo1} className="invo-logo" alt={"logo"} />
            <p className="section-inner-heading invo-heding-text">ONE-TO-ONE SESSION</p>
            <p className="at invo-heding-textp">We Offer U One-To-One Session Wherein We Understand Ur Challenges & Future Aspirations. We Also Determine How We Can Be Of Help To U For Managing Ur Financial Lives Efficiently.</p>
          </div>

          <div className="invo-box" >
            <img src={invo1} className="invo-logo" alt={"logo"} />
            <p className="section-inner-heading invo-heding-text">CUSTOMIZED PLAN</p>
            <p className="at invo-heding-textp">After Assessing Your Current Situation, We Draw Up A Customized Plan To Suit Your Specific Requirements With A Specific Time Bound Action Plan.</p>
          </div>

          <div className="invo-box" >
            <img src={invo1} className="invo-logo" alt={"logo"} />
            <p className="section-inner-heading invo-heding-text">IMPLEMENTATION</p>
            <p className="at invo-heding-textp">Once We Have The Client Buy-In To The Proposed Plan & Recommendations, We Oversee Hand-In-Hand The Implementation Process From Start To Finish.</p>
          </div>

          <div className="invo-box" >
            <img src={invo1} className="invo-logo" alt={"logo"} />
            <p className="section-inner-heading invo-heding-text">TRACKING EFFICIENCIES</p>
            <p className="at invo-heding-textp">We Monitor The Action Plan And Other Key Indicators At Periodic Regular Intervals, Thus Adjusting Recommended Strategies As Needed. It Is The Core Of Our Philosophy​</p>
          </div>
        </div>

      </div>



    </div>

  )
}

export default Investmentinfo