export const ytLinkModifier = (link  : string)=>{
    // console.log('ytLinkModifier',link)
    let extractedString = ''

    let str = 'https://www.youtube.com/embed/'
    const type1  = 'https://youtu.be/'

    let index = link.lastIndexOf('/')
    extractedString = link.substring(index+1 , link.length)
    // console.log('String Extracted',index,link.length,extractedString)

    str = str + extractedString;
    // alert(`Final String ${str}`)
    return str
}

