import React, { useEffect, useState } from 'react'
import ButtonA from '../atoms/ButtonA'
import ImageUploader from './ImageUploader'

import Propertyimage1 from '../assets/images/property/Property1.jpg';
import Propertypic1 from '../assets/images/property/Propertypic1.jpg';
import Propertypic2 from '../assets/images/property/Propertypic2.jpg';
import Propertypic3 from '../assets/images/property/Propertypic3.jpg';
import Propertypic4 from '../assets/images/property/Propertypic4.jpg';
import { useNavigate, useLocation } from 'react-router-dom';


import "../assets/css/comman/propertys.css"
import { FETCH } from '../services/Fetch.tsx';
const AddProjectForm = ({ id }) => {

    const location = useLocation()
    const navigate = useNavigate()

    let props = {
        prptId: location.state.id,
        mainImg: Propertyimage1,
        imgArr: [Propertypic1, Propertypic2, Propertypic3, Propertypic4, Propertypic1, Propertypic1],
        prjName: `Property Name Goes here.. ${location.state.id.substring(4)}`,
        prptDest: 'Property Destination Goes here....',
        prptDsc: `Property Description Goes here....`
    }

    const { prptId, mainImg, imgArr, prjName, prptDest, prptDsc } = props
    const [prptVal, setPrptVal] = useState(props)

    const handleChange = (e) => {
        // console.log('HAndling Text CHange', e.target.name, e.target.value)
        setPrptVal(prev => {
            return ({ ...prptVal, [e.target.name]: e.target.value })
        })
    }
    const handleSubmit = async (e) => {
       try {
         e.preventDefault()
         e.preventDefault()
         // console.log(prptVal)
 
         let formData = new FormData()
         // console.log('PRPT val',prptVal)
         formData.append("prptId",prptVal.prptId)
         formData.append("prjName",prptVal.prjName)
         formData.append("prptDest",prptVal.prptDest)
         formData.append("prptDsc",prptVal.prptDsc)
         formData.append("mainImg",prptVal.mainImg)
         for(let img of prptVal.imgArr){
             formData.append("imgArr",img)
         }
         
         // console.log('Form Data is ',formData)
         const response = await fetch('https://api.finainv.in/update-property',{
             method : 'POST',
             mode : 'cors',
             headers: {
                 Authorization: `Bearer ${localStorage.getItem('token')}`
             },
             body : formData
         })
         let data = await response.json()
         // console.log('Data Recieved ' , data)
         if(response.status===200){
             alert('Property Added Successfully')
             navigate('/property-projects')
         }
       } catch (error) {
            if(error){
                // console.log('ERROR in FETCH',error);
            }
       }
    }

    
    useEffect(() => {
        // console.log('****---mmm', prptVal)
    }, [prptVal])


    return (
        <div className="property-container" style={{ marginTop: '15px' }}>
            <p className="section-heading" style={{ textAlign: `center`, margin: 20 }}>Add New Property</p>
            <div className="property-container">
                <form className="property-main"  onSubmit={e=>handleSubmit(e)}  method='post'>
                    <div className="section-inner-heading property-heder-text">
                        <input className="section-inner-heading property-heder-text" name='prjName' value={prptVal.prjName} onChange={handleChange} />
                    </div>
                    <div className="property-text-main">
                        <div className="property-Pra-text1">
                            <div className="property-Pra-text-information">
                                <textarea name='prptDsc' value={prptVal.prptDsc} onChange={handleChange} className="property-Pra-text-information" cols={60} rows={10} />
                            </div>
                            <br />
                            <div className="property-text-address">Destination:<div className="property-text-address-span">
                                <textarea name='prptDest' value={prptVal.prptDest} onChange={handleChange} className='property-text-address property-text-address-span' cols={45} rows={2} />
                            </div>
                            </div>
                        </div>
                        <div className="property-Pra-text2">
                            <ImageUploader parentClass={'property-images'}
                                val={prptVal} setVal={setPrptVal} id={998} ele={mainImg} name={'mainImg'} mw={`650px`} mh={`450px`} />
                        </div>
                    </div>
                    <div className="property-pic1-main1">
                        <div className="scroll-content">
                            {imgArr.map((el, i) => {
                                return (
                                    <>
                                        <ImageUploader parentClass={'scroll-item'}
                                            val={prptVal} setVal={setPrptVal} key={i} id={i} ele={el} mw={`425px`} mh={`280px`} name={'imgArr'} />
                                    </>)
                            })}
                        </div>
                    </div>
                    
                    <ButtonA name={`ADD`} parentClass={`parent-flex-center`}></ButtonA>
                </form>
            </div>
        </div>
    )

}
export default AddProjectForm