import React from 'react'

const ListItem1 = (props) => {
    const style2 = {
        fontFamily:` "Poppins", sans-serif`,
        color: 'Black = ',
        fontWeight: 700,
        fontSize: 20,
        paddingLeft: `20px`,
        
    }
    const style3 = {
        fontFamily: `"Poppins", sans-serif`,
        color: 'Black = ',
        fontWeight: 300,
        fontSize: 20,  
    }
    return (
        <div className={`${props?.parentClass}`} >
            <div>
                {props.preColHeading}: <br/><br />

                <p style={style2}>{props.preColHeading1} <span style={style3}>{props.bodyMessage}</span></p><br />
                <p style={style2}>{props.preColHeading2} <span style={style3}>{props.bodyMessage1}</span></p>
            </div>
        </div>
    )
}

export default ListItem1
