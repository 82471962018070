import React from 'react'
import abimage from '../../assets/images/Slider2.jpeg'
// import abbackimg from '../../assets/images/Slider4.jpeg'
import images1 from '../../assets/images/Slider1.jpg'
import ServicesCard from '../../common/ServicesCard';

import testimonials1 from '../../assets/images/logo/J1.jpg';
import testimonials2 from '../../assets/images/logo/M1.jpg';
import testimonials3 from '../../assets/images/logo/R11.jpg';
import testimonials4 from '../../assets/images/logo/P11.jpg';

import '../../assets/css/containers/about.css'
import Homeimages1 from '../../assets/images/Cardsimages/homeloan.jpg';
import Homeimages2 from '../../assets/images/Cardsimages/Real-Estate.jpg';
import Homeimages3 from '../../assets/images/Cardsimages/Business Loans.jpg';
import Homeimages4 from '../../assets/images/Cardsimages/Personal Loans.jpeg';
import Homeimages5 from '../../assets/images/Cardsimages/Education Loans.jpg';
import Homeimages6 from '../../assets/images/Cardsimages/Vehicle Loans.jpg';
import Homeimages7 from '../../assets/images/Cardsimages/Life Insurance.jpg';
import Homeimages8 from '../../assets/images/Cardsimages/Health Insurance.jpg';
import Homeimages9 from '../../assets/images/Cardsimages/Wealth Management.jpeg';


const About = () => {
  return (
    <div className='about-main' style={{paddingTop:`40px`}}>
      <div className='about-container1 service-parent-flex-center'>
        <p className="section-heading about-text-heding-main1">About Us</p>
      </div>
      <p className="services-text-heding" style={{ textAlign: `center`,paddingTop:`20px` }}>Growth is Life</p>

      <div className='ab-subheading' style={{paddingTop:`20px`}}>
        Finasource Innovations excels as a comprehensive solution provider
      </div>
      <div className='at ab-para'>
        Finasource Innovations excels as a comprehensive solution provider in the financial landscape,
        specializing in Home Loans, Real Estate, Business Loans, Personal Loans, Education Loans, Vehicle Loans,
        Life Insurance, Health Insurance, and Wealth Management. Our expertise spans diverse sectors,
        offering tailored solutions to individuals and businesses. With a commitment to innovation,
        transparency, and client success, Finasource transforms the financial experience.
        Whether navigating real estate transactions, securing loans, or managing insurance needs,
        Finasource stands as a trusted partner, providing expert guidance and fostering financial well-being across various domains.
        Explore our offerings at finasourceinnovations.com for a holistic approach to financial prosperity.
      </div>

      <div className="about-background-image">
        <div className="about-para-container">
          <h1 className='section-inner-heading'>What Finasource Innovations Do </h1>
          <p className='at' style={{ color: `#16427f` }}>Finasource Innovations excels as a comprehensive solution provider in the financial landscape,
            specializing in Home Loans, Real Estate, Business Loans, Personal Loans, Education Loans, Vehicle Loans,
            Life Insurance, Health Insurance, and Wealth Management. Our expertise spans diverse sectors, offering
            tailored solutions to individuals and businesses. With a commitment to innovation, transparency,
            and client success, Finasource transforms the financial experience. Whether navigating real estate transactions,
            securing loans, or managing insurance needs, Finasource stands as a trusted partner, providing expert
            guidance and fostering financial well-being across various domains. Explore our offerings at
            finasourceinnovations.com for a holistic approach to financial prosperity.</p>
        </div>
      </div>
      <br />
      <div className="service-cards-main">
        <p className="section-heading about-text-heding-main1">Working Sector</p>

        <div className="service-cards">
          <ServicesCard
            imageSrc={Homeimages1}
            title="Home Loan"
            description="Facilitating home ownership with flexible financing options."
            ButtonName="Explore"
            navigation="/Home-Loan"
            sectionId='#homeLoan'
          />
          <ServicesCard
            imageSrc={Homeimages2}
            title="Real Estate Services"
            description="Navigating and streamlining real estate transactions for clients."
            ButtonName="Explore"
            navigation="/realestateinfo"
            sectionId='#realstateinfo'
          />
          <ServicesCard
            imageSrc={Homeimages3}
            title="Business Loan"
            description="Supporting business growth through tailored loan solutions."
            ButtonName="Explore"
            navigation="/Personal-Business-Loan"
            sectionId='#businessloan'

          />
          <ServicesCard
            imageSrc={Homeimages4}
            title="Personal Loan"
            description="Meeting individual financial requirements with personalized lending."
            ButtonName="Explore"
            navigation="/Personal-Business-Loan"
            sectionId='#personalloan'
          />
          <ServicesCard
            imageSrc={Homeimages5}
            title="Education Loan"
            description="Meeting individual financial requirements with personalized lending."
            ButtonName="Explore"
            navigation="/Education-Loan"
            sectionId='#educationloan'
          />
          <ServicesCard
            imageSrc={Homeimages6}
            title="Vehicle Loan"
            description="Simplifying vehicle acquisition through financing solutions."
            ButtonName="Explore"
            navigation="/Agriculture-Vehical-Loan"
            sectionId='#vehicle'
          />
          <ServicesCard
            imageSrc={Homeimages7}
            title="Life Insurance"
            description="offering protection and financial security through life insurance products."
            ButtonName="Explore"
            navigation="/insurance"
            sectionId='#insurance'
          />
          <ServicesCard
            imageSrc={Homeimages8}
            title="Health Insurance"
            description="Offering protection and financial security through life insurance products."
            ButtonName="Explore"
            navigation="/insurance"
            sectionId='#insurance'
          />
          <ServicesCard
            imageSrc={Homeimages9}
            title="Wealth Management"
            description="Expert guidance for optimizing and growing financial portfolios."
            ButtonName="Explore"
            navigation="/investmentinfo"
            sectionId='#investmentinfo'
          />
        </div>


      </div>
      <br />

      <div className="testimonials-text-main">
        <div className="testimonials-text-heding-main">
          <p className="section-heading">Testimonials</p>
        </div>


        <div className="testimonials-box-main" >
          <div className="testimonials-box" >
          <img src={testimonials1} className="testimonials-logo" alt={"logo"} />
          <p className="testimonials-heding-text">Manish Jain</p>
            <p className="testimonials-heding-textp">"Finasource transformed my home-buying experience with their seamless Home Loan process.
              Their expertise and transparency made them my go-to financial partner. "</p>
          </div>

          <div className="testimonials-box" >
          <img src={testimonials2} className="testimonials-logo" alt={"logo"} />
          <p className="testimonials-heding-text">Vijay B</p>
            <p className="testimonials-heding-textp">"Exceptional service from Finasource! Their tailored solutions and commitment
              to client satisfaction made securing a Business Loan a breeze. Highly recommended for financial needs. "</p>
          </div>

          <div className="testimonials-box" >
          <img src={testimonials3} className="testimonials-logo" alt={"logo"} />
          <p className="testimonials-heding-text">Mr Harmeet S.</p>
            <p className="testimonials-heding-textp">"Finasource's Wealth Management expertise exceeded my expectations. Their insightful guidance and innovative
              approach helped me optimize my financial portfolio for long-term success. Truly exceptional service! "</p>
          </div>

          <div className="testimonials-box" >
          <img src={testimonials4} className="testimonials-logo" alt={"logo"} />
          <p className="testimonials-heding-text">Amit S</p>
            <p className="testimonials-heding-textp">"Finasource Innovations made my Pune real estate journey effortless. Their expertise and seamless processes simplified transactions,
              ensuring I found my dream property hassle-free. Highly recommend their exceptional services! "</p>
          </div>
        </div>

      </div>

    </div>
  )
}

export default About