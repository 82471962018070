import React, { useState, useEffect, useLayoutEffect, useContext }  from 'react'

import '../assets/css/navigation/footer.css'
// import Instagram from '../assets/images/logo/Insta.png'
// import FB from '../assets/images/logo/FB.png'
// import Twitter from '../assets/images/logo/Twit.png'
// import Youtube from '../assets/images/logo/Youtube.png'
import { BsGeoAlt, BsTelephone, BsGoogle, BsTwitterX } from 'react-icons/bs';
import { TfiEmail } from "react-icons/tfi";
import { FiFacebook } from "react-icons/fi";
import { FaLinkedinIn, FaYoutube } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { scrollToTop } from '../utils/scrollToTop';
import { ytLinkModifier } from '../utils/utils.tsx';
import { FETCH } from '../services/Fetch.tsx';
import { Context } from '../../App.js';



const Footer = () => {
  const { editMode, setEditMode } = useContext(Context)
  
  // Handle Whatsapp Integration
  const phoneNumber = '+919518753980'; // Replace with the desired phone number
  const message = 'Hello!'; // Optional message
  
  const handleWhatsAppClick = () => {
    const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    window.location.href = whatsappLink;
  };
  
  // Handle call integration
  function handleCall() {
    const phoneNumber = '+919518753980'; // Replace with the phone number you want to call
    window.location.href = `tel:${phoneNumber}`;
  }
  
  return (
    <>
      <footer >

      <div className='footer-menu' data-aos="fade-right">
        <div className="footer-menu-useful-link">
          <div className="footer-link-headings footer-head">Home Loan</div>
          <div className="footer-links">
            <ul className="footer-link-list">
              <li><Link className="link" to="/homeLoancalculator" onClick={scrollToTop}>Home Loan Calculator</Link></li>
              <li><Link className="link" to="/eligibilitycalculator" onClick={scrollToTop}>Elegibility Loan Calculator</Link></li>
              <li><Link className="link" to="/Home-Loan" onClick={scrollToTop}>Home Loan</Link></li>
              <li><Link className="link" to="/Home-Loan" onClick={scrollToTop}>Loan Against Property</Link></li>
              <li><Link className="link" to="/Home-Loan" onClick={scrollToTop}>Mortgage Loan</Link></li>
            </ul>
          </div>
          <br />
          <br />
          <br />
          <br />
          <div className="footer-link-headings footer-head">Insurance</div>
          <div className="footer-links">
            <ul className="footer-link-list">
              <li><Link className="link" to="/insurance" onClick={scrollToTop}>Health Insurance</Link></li>
              <li><Link className="link" to="/insurance" onClick={scrollToTop}>Life Insurance</Link></li>
              <li><Link className="link" to="/insurance" onClick={scrollToTop}>General Insurance</Link></li>

              </ul>
            </div>
          </div>

        <div className="footer-menu-useful-link" data-aos="fade-up">
          <div className="footer-menu-region  footer-link-headings footer-head">Finance</div>
          <div className="footer-links">
            <ul className="footer-link-list">
              <li><Link className="link" to="/Personal-Business-Loan" onClick={scrollToTop}>Personal Loan</Link></li>
              <li><Link className="link" to="/Personal-Business-Loan" onClick={scrollToTop}>Business Loan</Link></li>
              <li><Link className="link" to="/Agriculture-Vehical-Loan" onClick={scrollToTop}>Agricultural Loan</Link></li>
              <li><Link className="link" to="/Agriculture-Vehical-Loan" onClick={scrollToTop}>Vehicle Loan</Link></li>
              <li><Link className="link" to="/Education-Loan" onClick={scrollToTop}>Education Loan</Link></li>
            </ul>
          </div>
          <br />
          <br/>
          <br />
          <br />
          <div className="footer-menu-contact-us" data-aos="fade-left">
          <div className="footer-contactUs-headings ">OFFICE ADDRESS</div>
          <div className="listls-1">
            <ul className="footer-contactUs-link-list">
              <div className="footer-adde-main">
                <BsGeoAlt className="icon3 icons-1" />
                <li className="footer-text1">A-205, Second Floor, KPCT <br />Mall, Besides Inamdar Hospital,<br />Fatima Nagar, Pune- <br />411040. Maharashtra </li>
              </div>

              <div className="footer-adde-main">
                <BsTelephone className="icon3" />
                <li className="footer-text1">+91 9518753980</li>
              </div>

              <div className="footer-adde-main">
                <TfiEmail className="icon3 icons-1 " />
                <li className="footer-text1">info@finainv.in<br /> finasourcein@gmail.com</li>
              </div>
            </ul>
          </div>
        </div>
        </div>

        <div className="footer-menu-useful-link" data-aos="fade-up">
          <div className="footer-menu-region  footer-link-headings footer-head">Real Estate</div>
          <div className="footer-links">
            <ul className="footer-link-list">
              <li><Link className="link" to="/realestateinfo" onClick={scrollToTop} >Property Advice</Link></li>
              <li><Link className="link" to="/realestateinfo" onClick={scrollToTop} >Property Rent</Link></li>
              <li><Link className="link" to="/realestateinfo" onClick={scrollToTop} >Property Sell</Link></li>
              <li><Link className="link" to="/realestateinfo" onClick={scrollToTop} >Property Buy</Link></li>
              <li><Link className="link" to="/realestateinfo" onClick={scrollToTop} >Property Resell</Link></li>

              </ul>
            </div>

          <br />
          <br />
          <br />
          <br />
          <div className="footer-links footer-link-headings ">
            <ul className="footer-link-list">
              <li><Link className="link" to="/" onClick={scrollToTop}>Home</Link></li>
              <li><Link className="link"  to="/about-us" onClick={scrollToTop}>About Us</Link></li>
              <li><Link className="link"  to="/contact" onClick={scrollToTop}>Contact Us</Link></li>
              <li><Link className="link"  to="/privacy" onClick={scrollToTop}>Privacy Policy</Link></li>
              <li><Link className="link"  to="/terms" onClick={scrollToTop}>Terms & Conditions</Link></li>
              <li><Link className="link"  to="/admin" onClick={scrollToTop}>Admin</Link></li>
            </ul>
          </div>
        </div>

          
        </div>


      <div className="footer-menu-socialLink">
        <div>
          <ul className="footer-social-link-list">
          {/*<li><Link className="link" to="#"><BsTwitterX className="logo" /></Link></li>*/}
          <li><Link className="link" to="https://www.facebook.com/profile.php?id=61551843677173"><FiFacebook className="logo" /></Link></li>
          {/*<li><Link className="link" to="#"><BsGoogle className="logo" /></Link></li>*/}
          <li><Link className="link" to="https://www.linkedin.com/in/ameet-dutt-3a428b7/"><FaLinkedinIn className="logo" /></Link></li>
          <li><Link className="link" to="https://www.youtube.com/channel/UCQcjTv0oeNCFS39zuVEHhaQ"><FaYoutube className="logo" /></Link></li>
          </ul>
        </div>
      </div>


        <div className='footer-copyright'>Copyright © 2024. All Rights Reserved Powered by <a href='https://brandliberty.in/' style={{ color: '#16427f' }}>Brand Liberty</a></div>
      </footer>

      <div>
      {!editMode?(
        <div>
        <div className="wtsp-btn wp-hover-effect" onClick={handleWhatsAppClick}><i className="fa-brands fa-whatsapp wp-hover-effect"></i></div>

        <div className="call-btn call-hover-effect" onClick={handleCall}><i className="fa-solid fa-phone call-hover-effect"></i></div>
        </div>
      ):(
        <div>
        <div className="call-btn call-hover-effect" onClick={()=>{setEditMode(false)}}><i class="fa-solid fa-power-off"></i></div>
        </div>
      )}
      </div>
    </>
  )
}

export default Footer