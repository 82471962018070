import React, { useState, useEffect, useContext } from 'react';
import '../../assets/css/comman/realestateinfo.css';
import Imgslider from '../../includes/Slider';
import Rent from '../../assets/images/logo/rent.png'
import Sell from '../../assets/images/logo/Sale.png'
import Buy from '../../assets/images/logo/Buy.png'
import Resell from '../../assets/images/logo/Resell.png'
import { Context } from '../../../App';
import TextinputA from '../../atoms/TextinputA';
import { ytLinkModifier } from '../../utils/utils.tsx';
import { FETCH } from '../../services/Fetch.tsx';
import ButtonA from "../../atoms/ButtonA";
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../utils/scrollToTop.js';
import ListItem from '../../atoms/ListItem.js';

const RealEstateInfo = () => {

  const { editMode, setEditMode } = useContext(Context)


  // handleCOntroller STates 
  const [yt10, setYt10] = useState('https://www.youtube.com/embed/4jnzf1yj48M?si=cawtecBIcugyNxGZ');
  const [yt11, setYt11] = useState('https://www.youtube.com/embed/4jnzf1yj48M?si=cawtecBIcugyNxGZ');

  // changeControllers 
  const onChangeYt10 = (text) => {
    // console.log('Text is changing', text)
    setYt10(prev => { return text })
  }
  const onChangeYt11 = (text) => {
    // console.log('Text is changing', text)
    setYt11(prev => { return text })
  }

  // handleControllers 
  const handleYT10Update = async () => {
    try {
      // console.log('YTLInk MOdifier', ytLinkModifier(yt10))
      setYt10(prev => {
        return ytLinkModifier(yt10)
      })

      let formData = {
        ytId: 'yt10',
        link: ytLinkModifier(yt10)
      }

      let { data, status } = await FETCH(
        'post',
        '/update-yt-link',
        '',
        formData
      )
      alert(data.message)
    } catch (error) {
      if (error) {
        // console.log('Error ***', error)
      }
    }
  }
  const handleYT11Update = async () => {
    try {
      // console.log('YTLInk MOdifier', ytLinkModifier(yt11))
      setYt11(prev => {
        return ytLinkModifier(yt11)
      })

      let formData = {
        ytId: 'yt11',
        link: ytLinkModifier(yt11)
      }

      let { data, status } = await FETCH(
        'post',
        '/update-yt-link',
        '',
        formData
      )
      alert(data.message)
    } catch (error) {
      if (error) {
        // console.log('Error ***', error)
      }
    }
  }

  // preFetch Calls
  let yt10call = async () => {
    try {
      let { data, status } = await FETCH(
        'get',
        '/get-yt-link',
        'yt10'
      )
      return { data, status }
    } catch (error) {
      if (error) {
        // console.log('Error ***', error)
      }
    }
  }
  let yt11call = async () => {
    try {
      let { data, status } = await FETCH(
        'get',
        '/get-yt-link',
        'yt11'
      )
      return { data, status }
    } catch (error) {
      if (error) {
        // console.log('Error ***', error)
      }
    }
  }

  useEffect(() => {
    // get the value of yt10 yt11
    yt10call()
      .then(data => {
        if (data.status === 200) {
          setYt10(data.data.data.link)
        } else {
          setYt10('https://www.youtube.com/embed/8WiDwcchrd8?si=XfuePfrDx5v96kkC')
        }
      })
      .catch(error => {
        if (error) {
          // console.log('Error ***', error)
        }
      })
    yt11call()
      .then(data => {
        if (data.status === 200) {
          setYt11(data.data.data.link)
        } else {
          setYt11('https://www.youtube.com/embed/8WiDwcchrd8?si=XfuePfrDx5v96kkC')
        }
      })
      .catch(error => {
        if (error) {
          // console.log('Error ***', error)
        }
      })

  }, [])


  const contantSlider = (<div className='section-heading slider-data'>
    Finasource Innovations
    <hr style={{
      height: `5px`,
      width: `25vw`,
      color: `#16427f`,
      backgroundColor: `#16427f`
    }} />
    <div className='section-inner-heading' style={{ color: `white`, display: `flex` }}>
      Financial & Real Estate Solutions</div>
  </div>)
  return (
    <section id="realstateinfo">
      <div className="">
        <div className="home-slider slider-shadow">
          <Imgslider sliderText={contantSlider} />
        </div>

      </div>
      <br />
      <br />

      <div className="">
        <p className="section-heading service-parent-flex-center">Real-Estate</p>
        <p className="font-size at ap-para" style={{ color: `#000000` }}>Real estate in India is dynamic, witnessing growth fueled by urbanization and population expansion. Major cities like Mumbai, Delhi, Bangalore, Pune and Hyderabad are key hubs for residential and commercial development. The sector faces challenges like regulatory complexities, but initiatives like RERA aim to enhance transparency. Affordable housing and sustainable projects are gaining momentum, reflecting changing market trends.</p>

        <div className="font-size at home-criteria-part">
          <p className="services-text-heding">
            Types of Real Estate Investments:
          </p>

          <ol className="font-size at ap-para">

            <li className="chooses-text-p at ap-para">1. Residential Real Estate:
              <ul >

                <li className="font-size at ap-para" style={{ margin: 0, padding: 0, paddingLeft: `10px`, color: `#000000` }}>• Single-family homes</li>
                <li className="font-size at ap-para" style={{ margin: 0, padding: 0, paddingLeft: `10px`, color: `#000000` }}>• Multi-family homes (apartment buildings)</li>
                <li className="font-size at ap-para" style={{ margin: 0, padding: 0, paddingLeft: `10px`, color: `#000000` }}>• Vacation properties</li>
              </ul>
            </li>
            <li className="chooses-text-p at ap-para">2.	Commercial Real Estate:
              <ul >

                <li className="font-size at ap-para" style={{ margin: 0, padding: 0, paddingLeft: `10px`, color: `#000000` }}>•	Office buildings</li>
                <li className="font-size at ap-para" style={{ margin: 0, padding: 0, paddingLeft: `10px`, color: `#000000` }}>•	Retail properties (malls, shopping centers)</li>
                <li className="font-size at ap-para" style={{ margin: 0, padding: 0, paddingLeft: `10px`, color: `#000000` }}>•	Industrial properties (warehouses, manufacturing facilities)</li>
              </ul></li>

            <li className="chooses-text-p at ap-para">3.	Real Estate Investment Trusts (REITs):
              <ul >

                <li className="font-size at ap-para" style={{ margin: 0, padding: 0, paddingLeft: `10px`, color: `#000000` }}>•	Publicly traded companies that own, operate, or finance income-generating real estate across a range of property sectors.</li>
              </ul></li>

            <li className="chooses-text-p at ap-para">4.	Real Estate Crowdfunding:
              <ul >

                <li className="font-size at ap-para" style={{ margin: 0, padding: 0, paddingLeft: `10px`, color: `#000000` }}>•	Online platforms allow multiple investors to pool funds for real estate projects, often offering opportunities for smaller investors to participate.</li>
              </ul></li>

            <li className="chooses-text-p at ap-para">5.	Real Estate Development:
              <ul >

                <li className="font-size at ap-para" style={{ margin: 0, padding: 0, paddingLeft: `10px`, color: `#000000` }}>•	Online platforms allow multiple investors to pool funds for real estate projects, often offering opportunities for smaller investors to participate.</li>
              </ul></li>

            <li className="chooses-text-p at ap-para">6.	Real Estate Limited Partnerships (RELPs):
              <ul >

                <li className="font-size at ap-para" style={{ margin: 0, padding: 0, paddingLeft: `10px`, color: `#000000` }}>•	Investors become limited partners in a real estate venture, usually with a general partner managing the project.</li>
              </ul></li>

            <li className="chooses-text-p at ap-para">7.	Fix and Flip Properties:
              <ul >

                <li className="font-size at ap-para" style={{ margin: 0, padding: 0, paddingLeft: `10px`, color: `#000000` }}>•	Buying properties with the goal of renovating and selling them quickly for a profit.</li>
              </ul></li>

            <li className="chooses-text-p at ap-para">8.	Real Estate Mutual Funds:
              <ul >

                <li className="font-size at ap-para" style={{ margin: 0, padding: 0, paddingLeft: `10px`, color: `#000000` }}>•	Investment funds that pool money from multiple investors to invest in a diversified portfolio of real estate assets.</li>
              </ul></li>

            <li className="chooses-text-p at ap-para">9.	Real Estate Syndication:
              <ul >

                <li className="font-size at ap-para" style={{ margin: 0, padding: 0, paddingLeft: `10px`, color: `#000000` }}>•	A group of investors collectively invests in a real estate project, typically led by a sponsor or syndicator who manages the investment.</li>
              </ul></li>

            <li className="chooses-text-p at ap-para">10.	Real Estate Notes:
              <ul >

                <li className="font-size at ap-para" style={{ margin: 0, padding: 0, paddingLeft: `10px`, color: `#000000` }}>•	Investing in mortgage notes or debt secured by real estate, providing for income through interest payments.</li>
              </ul></li>

            <li className="chooses-text-p at ap-para">11.	Real Estate ETFs (Exchange-Traded Funds):
              <ul >

                <li className="font-size at ap-para" style={{ margin: 0, padding: 0, paddingLeft: `10px`, color: `#000000` }}>•	Similar to mutual funds but traded on stock exchanges, these funds invest in real estate-related assets.</li>
              </ul></li>

            <li className="chooses-text-p at ap-para">12.	Real Estate Wholesaling:
              <ul >

                <li className="font-size at ap-para" style={{ margin: 0, padding: 0, paddingLeft: `10px`, color: `#000000` }}>•	Investors identify distressed properties, negotiate discounted prices, and then sell the contracts to other investors or end buyers for a fee.</li>
              </ul>
            </li>

          </ol>
          <div className="font-size at ap-para" style={{ color: `#000000` }}>Before investing in real estate, it's essential to thoroughly research each option, consider your financial goals and risk tolerance, and potentially seek advice from financial professionals or real estate experts. Each type of investment comes with its own set of risks and rewards.</div>
        </div>
      </div>
      <div className='bg-section-color' style={{padding:`60px 0`}}>
        <div className='services-text-heding service-parent-flex-center '>Our Specialization:</div>

        <div className="chooses-text-main">

          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`1. Residential Properties`}
            bodyMessage={` Finasource Innovations excels in residential real estate solutions, offering cutting-edge technology and personalized services. Elevate your property experience with our innovative solutions for a seamless and enriching living environment.`}
          />
          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`2. Commercial Properties`}
            bodyMessage={` Finasource Innovations offers cutting-edge real estate solutions for commercial properties, optimizing efficiency, and maximizing returns through innovative technologies and strategic insights, revolutionizing the commercial real estate landscape.`}
          />
          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`3. Fractional Investments`}
            bodyMessage={` Finasource Innovations offers cutting-edge Real Estate Solutions, enabling fractional investments in commercial properties across India. With a low entry point of Rs 25 lakh, investors enjoy attractive IRR, encompassing steady rental income and capital gains. Stringently governed by SEBI regulations, it presents an exciting avenue for diverse and lucrative real estate investments.`}
          />
          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`4. Holiday Homes`}
            bodyMessage={` Finasource Innovations offers unparalleled Real Estate Solutions for Holiday Homes, blending innovation and luxury. Elevate your vacation experience with our expertly crafted, unforgettable properties and personalized services.
    `}
          />
        </div>

      </div>

      <div className="">
        <p className="section-heading service-parent-flex-center" style={{paddingTop:`20px`}}>Advisory in Real-estate</p>
      </div>
      <div className="real-left">
        <div className="home-cal-logo">
          <img src={Rent} alt="" />
          <p className='section-inner-heading' style={{ fontSize: `2.5rem`, color: `#16427f` }}>Property Rent</p>
        </div>
        <div className="home-cal-text">
          <p className="font-size at ap-para">
            Finasource Innovations Property Rent offers a seamless and innovative solution for property rental management. Leveraging cutting-edge technology, they streamline the rental process, ensuring landlords and tenants experience efficiency and transparency. With a commitment to user-friendly platforms, Finasource transforms property renting into a hassle-free experience for all stakeholders.
          </p>
        </div>
        <Link to="/property-projects" onClick={scrollToTop}><ButtonA name="Our Property" parentClass={`section-parent-flex-center`}></ButtonA></Link>
      </div>
      <br />
      <div className="real-left2 bg-section-color">
        <div className="home-cal-logo1">
          <img src={Sell} alt="" />
          <p className='section-inner-heading' style={{ fontSize: `2.5rem`, color: `#16427f` }}>Property Sell</p>
        </div>
        <div className="home-cal-text1">
          <p className="font-size at ap-para">
            Finasource Innovations Property Sales excels in revolutionizing real estate transactions. With cutting-edge technology and a customer-centric approach, they streamline property sales, providing a seamless experience for buyers and sellers alike. Our innovative solutions redefine industry standards, making them a trusted partner in the dynamic landscape of real estate transactions.
          </p>
        </div>

        <Link to="/property-projects" onClick={scrollToTop}><ButtonA name="Our Property" parentClass={`section-parent-flex-center`}></ButtonA></Link>
      </div>


      <div className="chooses-video-main">

        {/*YT10*/}

        {!editMode ? (<iframe className="chooses-video" src={yt10}></iframe>) : (<div>
          <iframe className="chooses-video" src={yt10}>
          </iframe>
          <TextinputA label={'Enter Video URL'} placeholder={'Enter Video URL'} onChange={(v) => onChangeYt10(v)} />
          <ButtonA name={'Update'} parentClass={'parent-flex-center'} onClick={handleYT10Update} />
        </div>)}
      </div>

      <br />
      <div className="real-bottom1 bg-section-color">
        <div className="home-cal-logo">
          <img src={Buy} alt="" />
          <p className='section-inner-heading' style={{ fontSize: `2.5rem`, color: `#16427f` }}>Property Buy</p>
        </div>
        <div className="home-cal-text">
          <p className="font-size at ap-para">
            Finasource Innovations, headquartered in Pune, pioneers comprehensive real estate solutions across India and globally. Specializing in residential and commercial properties, crowd funding, land purchase, and investment in holiday homes, they offer diverse opportunities for investors and homeowners. With a commitment to excellence, Finasource is a trusted name in the dynamic real estate industry.


          </p>

        </div>
        <Link to="/property-projects" onClick={scrollToTop}><ButtonA name="Our Property" parentClass={`section-parent-flex-center`}></ButtonA></Link>
      </div>
      <br />
      <div className="real-bottom2 ">
        <div className="home-cal-logo1">
          <img src={Resell} alt="" style={{ paddingLeft: `10%` }} />
          <p className='section-inner-heading' style={{ fontSize: `2.5rem`, color: `#16427f` }}>Property Resell</p>
        </div>
        <div className="home-cal-text1">
          <p className="font-size at ap-para">
            Finasource Innovations Property Resale streamlines real estate transactions, providing a cutting-edge platform for seamless property resale. Leveraging innovative technology, it offers a user-friendly experience, efficient processes, and transparent dealings. With Finasource, clients navigate property resale effortlessly, enjoying a trusted and technologically advanced solution in the dynamic real estate market.
          </p>
        </div>

        <Link to="/property-projects" onClick={scrollToTop}><ButtonA name="Our Property" parentClass={`section-parent-flex-center`}></ButtonA></Link>
      </div>
      <div className=" bg-section-color">
        <div className="chooses-video-main">

          {/*YT11*/}

          {!editMode ? (<iframe className="chooses-video" src={yt11}></iframe>) : (<div>
            <iframe className="chooses-video" src={yt11}>
            </iframe>
            <TextinputA label={'Enter Video URL'} placeholder={'Enter Video URL'} onChange={(v) => onChangeYt11(v)} />
            <ButtonA name={'Update'} parentClass={'parent-flex-center'} onClick={handleYT11Update} />
          </div>)}
        </div>
      </div>

    </section>
  );
};

export default RealEstateInfo;
