import React, { useContext, useState } from 'react';
import styles from './LoginPage.module.css'; // Import the CSS file for styling
import { Context } from '../../App';
import { useNavigate } from 'react-router-dom';
import {FETCH} from '../services/Fetch.tsx'

const AdminLogin = () => {
    const {editMode , setEditMode} = useContext(Context)
    const navigate = useNavigate()
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Add your login logic here, e.g., sending a request to a server

        // console.log('User ID:', userId);
        // console.log('Password:', password);

        let formData = { 
            id : userId,password
        }

       try {
         const {data , status} = await FETCH(
             'post',
             '/create-session',
             '',
             formData
         )
         if(status===200){
             localStorage.setItem('token', data.data.token)
             setEditMode(true)
             navigate('/home')
         }else{
             localStorage.clear()
         }
         alert(data?.message)
       } catch (error) {
        if(error){
            alert('CHeck your Internet Connection',error)
            // console.log('Error ***',error)
          }
       }

        // Clear the input fields after submission
        setUserId('');
        setPassword('');
    };
    return (
        <div style={{textAlign : 'center',display : 'flex',justifyContent : 'center',flexDirection :'column', height : `80vh`}}>
        <h1>Admin Login</h1>
        <div className={styles.loginContainer}>
            <h2>Login</h2>
            <form onSubmit={handleSubmit} className={styles.form}>
                <div className={styles.inputGroup}>
                    <label htmlFor="userId">User ID:</label>
                    <input
                        className={styles.input}
                        type="text"
                        id="userId"
                        value={userId}
                        onChange={(e) => setUserId(e.target.value)}
                        required
                    />
                </div>
                <div className={styles.inputGroup}>
                    <label className={styles.label} htmlFor="password">Password:</label>
                    <input
                        className={styles.input}
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <div className={styles.inputGroup}>
                    <button className={styles.button} type="submit">Log In</button>
                </div>
            </form>
        </div>
        </div>
    );
};

export default AdminLogin;
