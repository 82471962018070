import React, { useState } from 'react';
import '../assets/css/containers/insurance.css';
import ButtonA from '../atoms/ButtonA';
import { FETCH } from '../services/Fetch.tsx';

const FormNo1 = () => {
  const [val, setVal] = useState({
    name: '',
    email: '',
    address: '',
    contactNumber: '', 
    birthDate: '',
    city: '',
    state: '',
    zip: '',
    loanType: 'home',
    
  });

  const handleChange = (e) => {
    setVal((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let { data, status } = await FETCH('post', '/loan-request', '', val);
      alert(data.message);
    } catch (error) {
      if (error) {
        alert('Check your Internet Connection');
      }
    }
  };

  return (
    <div className="insurance-box-container" style={{ marginTop: `40px` }}>
      <div className="insurance-box-main">
        <div className="insurance-text-main">
          <p className="insurance-text">Request a Quote</p>
        </div>
        <form className="in-form" onSubmit={handleSubmit}>
          <label>
            <select
              className="insurance-form-text"
              name="loanType"
              onChange={(v) => handleChange(v)}
            >
              <option value="home">Home Loan</option>
              <option value="personal">Personal Loans</option>
              <option value="business">Business Loans</option>
              <option value="agriculture">Agriculture Loan</option>
              <option value="vehical">Vehicle Loan</option>
              <option value="education">Education Loan</option>
            </select>
          </label>
          <br />
          <input
            value={val.name}
            name="name"
            onChange={handleChange}
            placeholder="Full Name"
            className="insurance-form-text1"
            required
          />
          <input
            name="email"
            type="email"
            value={val.email}
            onChange={handleChange}
            className="insurance-form-text1"
            placeholder="Email"
          />
          <input
            name="address"
            value={val.address}
            onChange={handleChange}
            className="insurance-form-text1"
            placeholder="Address"
          />
          <input
            type="tel"
            name="contactNumber"
            maxLength={10}
            value={val.contactNumber}
            onChange={handleChange}
            className="insurance-form-text1"
            placeholder="Contact Number"
            required
          />
          <input
            type="date"
            name="birthDate"
            value={val.birthDate}
            onChange={handleChange}
            className="insurance-form-text1"
            placeholder="dd-mm-yyyy"
          />
          <div className="insurance-input-text2">
            <input
              type="text"
              name="city"
              value={val.city}
              onChange={handleChange}
              className="insurance-form-text2"
              placeholder="City"
            />
            <input
              type="text"
              name="state"
              value={val.state}
              onChange={handleChange}
              className="insurance-form-text2"
              placeholder="State/Province"
            />
          </div>
          <input
            type="number"
            maxLength={6}
            name="zip"
            value={val.zip}
            onChange={handleChange}
            className="insurance-form-text1"
            placeholder="Zip/Postal Code"
          />
          {/* New input field for contact number */}
          
          <div className="Insurance-btn">
            <ButtonA name={'submit'} parentClass={'Insurance-submit'} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormNo1;
