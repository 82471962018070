import React, { useState } from 'react';

const ImageUploader = (props) => {
  const [selectedImage, setSelectedImage] = useState(props.ele);

  const handleImageChange = (e) => {
    // console.log(e.target.name)
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        props.setVal(prev =>{
          if(e.target.name === 'imgArr'){
            const newArr = [...prev.imgArr];
            newArr[e.target.id] = e.target.files[0]
            return {...prev , [e.target.name] : newArr}
          }else{
            return {...prev , [e.target.name] : e.target.files[0]}
          }
        })
        setSelectedImage(event.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      // If the user deselected the file, clear the selected image
      setSelectedImage(null);
    }
  };

  const handleClearImage = () => {
    setSelectedImage(null);
  };

  const handleSubmit = (e) => {
    // Add your submit logic here
    // console.log('Image submitted:', selectedImage);
  };
  // console.log('SELECTED IMAGE',selectedImage)

  return (
    <div>
      <div className={`${props?.parentClass} parent-flex-center`} style={{margin : 0}}>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: 'none' }}
          id={`${props.id}`}
          className={props?.parentClass}
          name={props.name}
        />
        <label htmlFor={`${props.id}`} className = {`parent-flex-center`} style={{margin : 0}}>
          <div
            style={{
              border: '2px dashed #ddd',
              borderRadius: '8px',
              textAlign: 'center',
              position: 'relative',
            }}
          >
            {selectedImage ? (
              <>
                <img
                  src={selectedImage}
                  alt="Selected"
                  style={{ width: `${props.mw}`, height: `${props.mh}`, objectFit: 'cover' }}
                />
                <button
                  onClick={handleClearImage}
                    style={{
                    position: 'absolute',
                    top: '8px',
                    right: '8px',
                    background: 'transparent',
                    border: 'none',
                    color: '#ff0000',
                    cursor: 'pointer',
                    border : 'solid'
                  }}
                >
                  X
                </button>
              </>
            ) : (
              <span className= {`parent-flex-center`}>Click to browse an image</span>
            )}
          </div>
        </label>
      </div>
    </div>
  );
};

export default ImageUploader;
