import React, { useState } from 'react';
import eligibilityImage from '../../assets/images/pics/eligibilityloan.jpeg';
import ecimage from '../../assets/images/Slider4.jpeg';
import "../../assets/css/containers/eligibilitycal.css";
import ButtonA from '../../atoms/ButtonA';

const EligibilityCalculator = () => {
  const [netIncome, setNetIncome] = useState('');
  const [tenure, setTenure] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [otherEmi, setOtherEmi] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [employmentType, setEmploymentType] = useState('salaried'); // Default to salaried
  const [cibilScore, setCibilScore] = useState('');
  const [monthlyDeduction, setMonthlyDeduction] = useState('');
  const [loanAmount, setLoanAmount] = useState(null);
  const [error, setError] = useState('');
  const [p, setP] = useState(null); // State to hold the p value
 

  const handleNetIncomeChange = (e) => {
    setNetIncome(e.target.value);
  };

  const handleTenureChange = (e) => {
    setTenure(e.target.value);
  };

  const handleInterestRateChange = (e) => {
    setInterestRate(e.target.value);
  };

  const handleOtherEmiChange = (e) => {
    setOtherEmi(e.target.value);
  };

  const handleBirthdateChange = (e) => {
    setBirthdate(e.target.value);
  };

  const handleEmploymentTypeChange = (e) => {
    setEmploymentType(e.target.value);
  };

  const handleCibilScoreChange = (e) => {
    setCibilScore(e.target.value);
  };

  const handleMonthlyDeductionChange = (e) => {
    setMonthlyDeduction(e.target.value);
  };

  const calculateLoanAmount = () => {

    console.log('****Calculating***')

    // Input validation
    if (!netIncome || !tenure || !otherEmi || !birthdate || !cibilScore || !monthlyDeduction) {
      setError('Please enter all values.');
      return;
    }

    const netIncomeValue = parseFloat(netIncome);
    const tenureValue = parseInt(tenure, 10);
    const interestRateValue = parseFloat(interestRate);
    const otherEmiValue = parseFloat(otherEmi);
    let birthdateValue = new Date(birthdate).getTime();
    let factor = 70
    if (employmentType.toLowerCase() === 'salaried') {
      factor = 60
    }
    let todays = new Date(birthdateValue + factor * 365 * 24 * 60 * 60 * 1000).getTime() - new Date().getTime()
    let diff = todays
    diff = diff / 1000;//got seconds
    diff = diff / 60 //got minutes
    diff = diff / 60 //got hours
    diff = diff / 24 // got days
    diff = diff / 365 // got years 
    console.log('yeasr remaining', diff)
    diff = diff * 12 //got months
    console.log('months reming ', diff)

    if (isNaN(netIncomeValue) || isNaN(tenureValue) || isNaN(otherEmiValue) || isNaN(parseInt(cibilScore)) || isNaN(parseFloat(monthlyDeduction))) {
      setError('Invalid input. Please enter valid numeric values.');
      return;
    }

    const monthlyInterestRate = interestRateValue / 100 / 12;
    const numberOfPayments = tenureValue * 12;

    // Adjust percentage based on net income and employment type
    let percentage = 0.00;
    if (netIncomeValue > 500000) {
      percentage = 0.75;
    }
    else if (netIncomeValue > 200000) {
      percentage = 0.70;
    }
    else if (netIncomeValue > 50000) {
      percentage = 0.65;
    }
    else if (netIncomeValue > 20000) {
      percentage = 0.60;
    }
    else if (percentage === 0.00) {
      percentage = 0.50
    }

    console.log('Percentage', percentage)


    let rate = 0.00;
    if (cibilScore >= 800) {
      rate = 0.084;
    }
    else if (cibilScore > 770) {
      rate = 0.084;
    }
    else if (cibilScore > 750) {
      rate = 0.0855;
    }
    else if (cibilScore > 725) {
      rate = 0.0885;
    }
    else if (cibilScore > 700) {
      rate = 0.095;
    }
    else if (cibilScore < 700) {
      rate = 0.1060;
    }
    else if (cibilScore === -1) {
      rate = 0.0905
    }

    if (diff < tenure) {
      alert('Choose A less tenure plan')
    }
    console.log('Rate', rate, rate / 12)
    // Using the calculated percentage of the monthly income for the loan amount calculation
    // const loanAmount = (netIncomeValue * percentage - otherEmiValue - parseFloat(monthlyDeduction)) * ((1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments)) / monthlyInterestRate);

    const loanAmount = (netIncomeValue * percentage - otherEmiValue - parseFloat(monthlyDeduction))

    let a = loanAmount * 12
    console.log('Amount', a, "  LaonAmount", loanAmount)
    let r = rate
    let tf = Math.pow((1 + r / 12), (tenure))
    let tfp = Math.pow((1 + r / 12), (tenure)) - 1

    let p = (loanAmount * tfp) / ((r / 12) * tf)
    console.log('Opening amaount', p)

    setP(p);
    setP(new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(p.toFixed(2)));

    // emi = loanAmount
    // opening amount  = p
    // yearly interest  = (a - p)/(tenure/12)


    // Check for valid result
    if (!isFinite(loanAmount) || loanAmount <= 0) {
      setError('Invalid calculation result. Please check your input values.');
      return;
    }

    setLoanAmount(new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(loanAmount.toFixed(2)));
    setError('');
  };

  const handleReset = () => {
    setNetIncome('');
    setTenure('');
    setInterestRate('');
    setOtherEmi('');
    setBirthdate('');
    setEmploymentType('salaried'); // Reset employment type to default
    setCibilScore('');
    setMonthlyDeduction('');
    setLoanAmount(null);
    setError('');
  };

  return (
    <div className='eligibility-main'>
      <div className="eligi-img-fluid loan-img-fluid">
        <h2>Eligibility Calculator</h2>
      </div>
      <br />
      <p className="section-heading" style={{ marginBottom: 40, textAlign: 'center' }}>Eligibility Calculator</p>
      <div className="eligibility-calculator">
        <div className="form-container">

          <div className="input-container listcontainer">
            <label className='section-inner-heading'>Employment Type:</label>
            <div className="employetype" style={{padding: '10px'}}>
              <div style={{display: 'flex',flexDirection:'row',justifyContent: 'space-between'}}>
                <label  style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                  Salaried
                </label>
                <input
                  style={{ margin: '0px', padding: '0px', width: '4%' }}
                  type="radio"
                  value="salaried"
                  checked={employmentType === 'salaried'}
                  onChange={handleEmploymentTypeChange}
                />
              </div>
              <div style={{display: 'flex',flexDirection:'row',justifyContent: 'space-between'}}>
                <label style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                  Self Employed
                </label>
                <input
                  style={{ margin: '0px', padding: '0px', width: '4%' }}
                  type="radio"
                  value="self-employed"
                  checked={employmentType === 'self-employed'}
                  onChange={handleEmploymentTypeChange}
                />
              </div>
            </div>
          </div>
          <div className="input-container">
            <label htmlFor="birthdate" className='section-inner-heading'>Birthdate:</label>
            <input
              type="date"
              id="birthdate"
              value={birthdate}
              onChange={handleBirthdateChange}
            />
          </div>

          <div className="input-container">
            <label htmlFor="netIncome" className='section-inner-heading'>Net Income (Monthly):</label>
            <input
              type="number"
              id="netIncome"
              value={netIncome}
              onChange={handleNetIncomeChange}
            />
          </div>
          <div className="input-container">
            <label htmlFor="tenure" className='section-inner-heading'>Tenure (Months):</label>
            <input
              type="number"
              id="tenure"
              value={tenure}
              onChange={handleTenureChange}
            />
          </div>

          <div className="input-container">
            <label htmlFor="otherEmi" className='section-inner-heading'>Other EMI (Monthly):</label>
            <input
              type="number"
              id="otherEmi"
              value={otherEmi}
              onChange={handleOtherEmiChange}
            />


          </div>

          <div className="input-container listcontainer">
            <label htmlFor="cibilScore" className='section-inner-heading'>CIBIL Score:</label>
            <label >
              <select className="insurance-form-text" name='insuranceType' onChange={handleCibilScoreChange} id="cibilScore">
                <option value={801}>Above 800</option>
                <option value={790}>771 and above but less than 800</option>
                <option value={760}>751 and above but less than 771</option>
                <option value={730}>726 and above but less than 751</option>
                <option value={710}>701 and above but less than 726</option>
                <option value={699}>Less than 701</option>
                <option value={-1}>(-1)</option>
              </select>
            </label>
            {/*<input
              type="number"
              id="cibilScore"
              value={cibilScore}
              onChange={handleCibilScoreChange}
             />*/}
          </div>
          <div className="input-container">
            <label htmlFor="monthlyDeduction" className='section-inner-heading'>Monthly Deduction (tax,NPS etc.):</label>
            <input
              type="number"
              id="monthlyDeduction"
              value={monthlyDeduction}
              onChange={handleMonthlyDeductionChange}
            />
          </div>


          <div style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap'
          }}>
            <ButtonA
              name="Calculate"
              onClick={calculateLoanAmount}
            />
            <ButtonA
              style={{ marginLeft: 4 }}
              name="Reset"
              onClick={handleReset}
            />
          </div>

          {error && (
            <div className="error-message">
              <p>{error}</p>
            </div>
          )}

          {p !== null && !error && (
            <div className="result-group">
              
              <h1>Your Eligible loan amount</h1>
              <p> {p}</p>
            </div>
          )}
        </div>

      </div>

      

      <div className='at ab-para'>
      <div className='ab-subheading' >
      Loan Calculator Disclaimer:
      </div>
     
        Information which you calculate from this Calculator is intended for use by you as a guide only, it is not an offer and has no legal effect on any contracts entered into by you 'the client'.
         This simulation and the resulting calculations do not constitute a loan application or offer. The figures and formulae used within this calculator may change at any time without notice.
        Should you apply for any loan product with Finasource Innovations, we will make our own calculations and we will not necessarily take your calculations into account.
         All Interest rates displayed are guides only and repayments as a result of this simulation have no legal offering.
        Finasource Innovation accepts no responsibility for any losses arising from any use of or reliance upon any calculations or conclusions reached using the calculator.
        Information provided on this calculator does not constitute an offer of finance. Any loan request is subject to assessment against normal lending criteria.
         Fees and charges may apply. This calculation does not take into account any fees, charges or government taxes which may apply to either the calculation of the loan amount or the monthly repayments,
         such as GST or Luxury Car Tax (where applicable).
        The calculator does not produce results based on your individual circumstances, suitable loan products or features that might suit you.
         This is something that one of our experienced team members can assist you with when you are making your formal application. The rates required to produce the example repayment in the calculator relies on user inputted data.

        If you have further questions please feel free to call us on (9518753980)
      </div>
    </div>
  );
};

export default EligibilityCalculator;