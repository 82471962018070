import React, { useState, useEffect, useLayoutEffect, useContext } from 'react'
import LoanImage from '../../assets/images/Slider2.jpeg'
import Images1 from '../../assets/images/lona/eductionloan1.jpg';
import Images2 from '../../assets/images/lona/eductionaloan2.jpg';
import "../../assets/css/comman/homeloan.css";
import ButtonA from "../../atoms/ButtonA";
import TextinputA from '../../atoms/TextinputA';
import ListItem from '../../atoms/ListItem';
import { Context } from '../../../App.js';
import { ytLinkModifier } from '../../utils/utils.tsx';
import { FETCH } from '../../services/Fetch.tsx';
import { Link } from "react-router-dom";
import { scrollToTop } from '../../utils/scrollToTop';

const LoanAV = () => {

  const { editMode, setEditMode } = useContext(Context)
  // handleCOntroller STates 
  const [yt7, setYt7] = useState('https://www.youtube.com/embed/yydb_gBpEA0?si=ZrmTv17CzgOpxFV_');
  const [yt8, setYt8] = useState('https://www.youtube.com/embed/yydb_gBpEA0?si=ZrmTv17CzgOpxFV_');

  // changeControllers 
  const onChangeYt7 = (text) => {
    // console.log('Text is changing', text)
    setYt7(prev => { return text })
  }
  const onChangeYt8 = (text) => {
    // console.log('Text is changing', text)
    setYt8(prev => { return text })
  }

  // handleControllers 
  const handleYT7Update = async () => {
    try {
      // console.log('YTLInk MOdifier', ytLinkModifier(yt7))
      setYt7(prev => {
        return ytLinkModifier(yt7)
      })

      let formData = {
        ytId: 'yt7',
        link: ytLinkModifier(yt7)
      }

      let { data, status } = await FETCH(
        'post',
        '/update-yt-link',
        '',
        formData
      )
      alert(data.message)
    } catch (error) {
      if (error) {
        // console.log('Error ***', error)
      }
    }
  }
  const handleYT8Update = async () => {
    try {
      // console.log('YTLInk MOdifier', ytLinkModifier(yt8))
      setYt8(prev => {
        return ytLinkModifier(yt8)
      })

      let formData = {
        ytId: 'yt8',
        link: ytLinkModifier(yt8)
      }

      let { data, status } = await FETCH(
        'post',
        '/update-yt-link',
        '',
        formData
      )
      alert(data.message)
    } catch (error) {
      if (error) {
        // console.log('Error ***', error)
      }
    }
  }

  // preFetch Calls
  let yt7call = async () => {
    try {
      let { data, status } = await FETCH(
        'get',
        '/get-yt-link',
        'yt7'
      )
      return { data, status }
    } catch (error) {
      if (error) {
        // console.log('Error ***', error)
      }
    }
  }
  let yt8call = async () => {
    try {
      let { data, status } = await FETCH(
        'get',
        '/get-yt-link',
        'yt8'
      )
      return { data, status }
    } catch (error) {
      if (error) {
        // console.log('Error ***', error)
      }
    }
  }

  useEffect(() => {
    // get the value of yt7
    yt7call()
      .then(data => {
        if (data.status === 200) {
          setYt7(data.data.data.link)
        } else {
          setYt7('https://www.youtube.com/embed/8WiDwcchrd8?si=XfuePfrDx5v96kkC')
        }
      })
      .catch(error => {
        if (error) {
          // console.log('Error ***', error)
        }
      })
    yt8call()
      .then(data => {
        if (data.status === 200) {
          setYt8(data.data.data.link)
        } else {
          setYt8('https://www.youtube.com/embed/8WiDwcchrd8?si=XfuePfrDx5v96kkC')
        }
      })
      .catch(error => {
        if (error) {
          // console.log('Error ***', error)
        }
      })

  }, [])

  return (
    <div>
      <div id="agricultureloan" className="container">
        <div className="agri-img-fluid loan-img-fluid">
          <h2></h2>
        </div>
        <p className="section-heading" style={{ margin: 20, textAlign: `center` }}>Agriculture Loan</p>

        <p className="font-size at ap-para">Agriculture loans, essential for farmers, provide financial support to enhance agricultural productivity. These loans cover expenses like dairy, seeds, fertilizers, equipment, and irrigation. Aimed at sustaining rural economies, agriculture loans facilitate farmers in achieving optimal yields and fostering agricultural development, thereby contributing to food security and economic stability</p>

        <div className="at home-criteria-part" style={{ lineHeight: `0.5` }}>
          <div className='section-inner-heading'>Eligibility Criteria:</div>
            <div className="font-size at ap-para">Eligibility for agriculture loan typically includes land ownership, farming experience, credit history, and a viable business plan. Requirements may vary among financial institutions.</div>
        </div>
        <div className="at home-criteria-part" style={{ lineHeight: `0.5` }}>
          <div className='section-inner-heading'>Our Offerings:</div>
            <div className="font-size at ap-para">Finasource Innovations facilitates Agriculture Loans via renowned banks, providing farmers with tailored financial solutions for crop cultivation, equipment purchase, and other agri-needs.</div>
        </div>
        <Link to="/loanform" onClick={scrollToTop}><ButtonA name={`Apply Now`}></ButtonA></Link>
        <div className="chooses-video-main">

          {/*YT7*/}

          {!editMode ? (<iframe className="chooses-video" src={yt7}></iframe>) : (<div>
            <iframe className="chooses-video" src={yt7}>
            </iframe>
            <TextinputA label={'Enter Video URL'} placeholder={'Enter Video URL'} onChange={(v) => onChangeYt7(v)} />
            <ButtonA name={'Update'} parentClass={'parent-flex-center'} onClick={handleYT7Update} />
          </div>)}
        </div>

      </div>
      <br /> <br />

      <div className="container bg-section-color" id='vehicle'>

        <div className="vehical-img-fluid loan-img-fluid">
          <h2></h2>
        </div>

        <p className="section-heading" style={{ margin: 20, textAlign: `center` }}>Vehical Loan</p>


      



        <p className="font-size at ap-para">A vehicle loan is a financial arrangement where a borrower secures funds to purchase a vehicle. Typically offered by banks or financial institutions, these loans involve fixed or variable interest rates with specified repayment terms. The vehicle itself serves as collateral, providing lenders security while enabling borrowers to acquire a vehicle through manageable installments.</p>

        <div className="at home-criteria-part" style={{ lineHeight: `0.5` }}>
          <div className='section-inner-heading'>Eligibility Criteria:</div>
            <div className="font-size at ap-para">To qualify for a vehicle loan, applicants typically need a stable income, good credit score, valid identification, proof of residence, and compliance with age and income requirements.</div>
        </div>
        <div className="at home-criteria-part" style={{ lineHeight: `0.5` }}>
          <div className='section-inner-heading'>Our Offerings:</div>
            <div className="font-size at ap-para">Finasource Innovations provides hassle-free vehicle loans through top banks, ensuring competitive rates, quick approvals, and flexible terms. Drive your dreams with our seamless financing solutions today.</div>
        </div>
        <Link to="/loanform" onClick={scrollToTop}><ButtonA name={`Apply Now`}></ButtonA></Link>
        <div className="chooses-video-main">

        {/*YT8*/}

        {!editMode ? (<iframe className="chooses-video" src={yt8}></iframe>) : (<div>
          <iframe className="chooses-video" src={yt8}>
          </iframe>
          <TextinputA label={'Enter Video URL'} placeholder={'Enter Video URL'} onChange={(v) => onChangeYt8(v)} />
          <ButtonA name={'Update'} parentClass={'parent-flex-center'} onClick={handleYT8Update} />
        </div>)}
      </div>
      <br/>

      </div>

    </div>
  )
}

export default LoanAV