import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropertySection from './PropertySection';
import styles from './pagination.module.css'
import ButtonA from '../atoms/ButtonA';
import { FETCH } from '../services/Fetch.tsx';
import server from '../services/server.tsx'
import { Context } from '../../App.js';

const itemsPerPage = 2; // Number of items to display per page

const PaginationComponent = () => {
  const {editMode , setEditMode} = useContext(Context)
  const navigate = useNavigate()
  // console.log('SERVER',server.server , typeof(server.server))
  const [data , setData] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([])
  const [totalPages , setTotalPages] = useState(0)
 

  const handlePageChange = (newPage) => {
    if(newPage <=totalPages){
      setCurrentPage(newPage);
    }
  };

  // prefetch call to Property details 
  const propertyCall = async ()=>{
    let {data , status} = await FETCH(
      'get',
      '/get-property',
      ''
    )
    return {data , status}
  }
  useEffect(()=>{
    try {
      propertyCall()
      .then(data=>{
        // console.log('PROPERTY ARRAY',data.data.data)
        setTotalPages(Math.ceil(data.data.data.length / itemsPerPage));
        if(data.data.data.length===0){
          alert('NO PROPERTY TO SHOW')
        }
        setData(data.data.data)
      })
      .catch((err)=>{
      })
    } catch (error) {
      // console.log('ERROR IN CALLING PORPERTY',error)
    }
  },[])
  useEffect(()=>{
     // Calculate the index range for the current page
     let startIndex = (currentPage - 1) * itemsPerPage;
     let endIndex = startIndex + itemsPerPage;
 
     // Slice the data array to get the items for the current page
     setCurrentData(data.slice(startIndex, endIndex));
     // console.log('CUrrebt dta', currentData)
  },[totalPages ,currentPage])

  return (
    <div className={styles.paginationContainer}>
      <p className="section-heading" style={{ textAlign: `center`, margin: 20 }}>Property Details</p>
      <div className="property-container">
        {/* Render the data for the current page */}

        {currentData.map((item, index) => (
          <PropertySection
            prptId={item.prptId}
            mainImg={item.mainImg}
            imgArr={item.imgArr}
            prjName={item.prjName}
            prptDest={item.prptDest}
            prptDsc={item.prptDsc}
            server={server.server}
          />
        ))}

        {/* Render pagination controls */}
        <div className={styles.paginationControls}>
          <button
            className={styles.paginationButton}
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className={styles.pageInfo}>{`Page ${currentPage} of ${totalPages}`}</span>
          <button
            className={styles.paginationButton}
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
        {((editMode)&&((currentPage === totalPages)||(totalPages===0))) ? (<ButtonA name='Add New Project' onClick={() => { navigate('/new-project',{state :{id : `prpt${data.length+1}`}}) }} />) : ('')}
      </div>
    </div>
  );
};

export default PaginationComponent;
