import React from 'react'

const ListItem = (props) => {
    // { parentClass, preColHeading, bodyMessage }
    const style1 = {
        fontFamily: `"Poppins", sans-serif`,
        color: 'Black = ',
        fontWeight: 300,
        fontSize: 20
}

return (
    <div className={`${props?.parentClass}`} >
        <div>
            {props.preColHeading}: <span style={style1}>{props.bodyMessage}</span>
        </div>
    </div>
)
}

export default ListItem