import React, { useState } from 'react';
import lcimage from '../../assets/images/Slider3.jpeg'
import "../../assets/css/containers/homeLoanCalculator.css"; // Import the CSS file
import calculatorImage from '../../assets/images/pics/homeloan.jpeg'; // Import your calculator image
import logoimg from '../../assets/images/logo.png'
import ButtonA from '../../atoms/ButtonA';

const HomeLoanCalculator = () => {
  const [principal, setPrincipal] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [loanTerm, setLoanTerm] = useState('');
  const [monthlyPayment, setMonthlyPayment] = useState(null);

  const calculateMonthlyPayment = () => {
    const principalAmount = parseFloat(principal);
    const rate = parseFloat(interestRate) / 100 / 12;
    const term = parseFloat(loanTerm) * 12;

    const monthlyPayment =
      (principalAmount * rate) / (1 - Math.pow(1 + rate, -term));

    setMonthlyPayment(monthlyPayment.toFixed(2));
  };

  const resetFields = () => {
    setPrincipal('');
    setInterestRate('');
    setLoanTerm('');
    setMonthlyPayment(null);
  };

  return (
    <div className='loan-calc-main'>
      <div className="homelcal-img-fluid loan-img-fluid">
        <h2></h2>
      </div>
      <br />
      <p className="section-heading" style={{ marginBottom: 40, textAlign: `center` }}>Home Loan Calculator</p>
      <div className="calculator-container">

        <div className="calculator-content">
          <div className="input-group">
            <label className='section-inner-heading'>Principal Amount (₹):</label>
            <input
              className='input'
              type="number"
              value={principal}
              onChange={(e) => setPrincipal(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label className='section-inner-heading'>Interest Rate (%):</label>
            <input
              className='input'
              type="number"
              value={interestRate}
              onChange={(e) => setInterestRate(e.target.value)}
            />
          </div>
          <div className="input-group">
            <label className='section-inner-heading'>Loan Term (Years):</label>
            <input
              className='input'
              type="number"
              value={loanTerm}
              onChange={(e) => setLoanTerm(e.target.value)}
            />
          </div>
          <div style={{
            display: `flex`,
            flexDirection: `row`,
            flexWrap: `wrap`
          }}>
            <ButtonA
              name="Calculate"
              onClick={calculateMonthlyPayment} />

            <ButtonA
              style={{ marginLeft: 4 }}
              name="Reset"
              onClick={resetFields} />

          </div>
          {monthlyPayment !== null && (
            <div className="result-group">
              <h2>Monthly Emi:</h2>
              <p>₹{monthlyPayment}</p>
            </div>
          )}
        </div>

      </div>
      

      <div className='at ab-para'>
      <div className='ab-subheading' >
      Loan Calculator Disclaimer:
      </div>
     
        Information which you calculate from this Calculator is intended for use by you as a guide only, it is not an offer and has no legal effect on any contracts entered into by you 'the client'.
         This simulation and the resulting calculations do not constitute a loan application or offer. The figures and formulae used within this calculator may change at any time without notice.
        Should you apply for any loan product with Finasource Innovations, we will make our own calculations and we will not necessarily take your calculations into account.
         All Interest rates displayed are guides only and repayments as a result of this simulation have no legal offering.
        Finasource Innovation accepts no responsibility for any losses arising from any use of or reliance upon any calculations or conclusions reached using the calculator.
        Information provided on this calculator does not constitute an offer of finance. Any loan request is subject to assessment against normal lending criteria.
         Fees and charges may apply. This calculation does not take into account any fees, charges or government taxes which may apply to either the calculation of the loan amount or the monthly repayments,
         such as GST or Luxury Car Tax (where applicable).
        The calculator does not produce results based on your individual circumstances, suitable loan products or features that might suit you.
         This is something that one of our experienced team members can assist you with when you are making your formal application. The rates required to produce the example repayment in the calculator relies on user inputted data.

        If you have further questions please feel free to call us on (9518753980)
      </div>
    </div>
  );
};

export default HomeLoanCalculator;