import React, { useState, useEffect, useLayoutEffect, useContext } from 'react'
import '../../assets/css/containers/insurance.css'
import socialimpact from '../../assets/images/property/Property4.jpg'
import esg from '../../assets/images/property/Property4.jpg'
import government from '../../assets/images/property/Property4.jpg'
import hcimage from '../../assets/images/Slider2.jpeg'
import healthimage from '../../assets/images/health-img.jpeg'
import TextinputA from '../../atoms/TextinputA.js';
import ButtonA from '../../atoms/ButtonA.js'
import { Context } from '../../../App';
import { ytLinkModifier } from '../../utils/utils.tsx';
import { FETCH } from '../../services/Fetch.tsx';
import { Link } from 'react-router-dom';

const Insurance = () => {

  const { editMode, setEditMode } = useContext(Context)
  const [index, setIndex] = useState(1)

  const content1 = (


    <div className='insurance-section-specific-content'>
      <div className="insurance-social-impact-content-box">
        <h2 className="section-heading insurance-social-impact-head heading-font-size">Health Insuarance</h2>
        <p className="at insurance-social-impact-content">
          Health insurance take care of all or most of the medical expenses of an insured person. The coverage usually includes hospitalization,
          surgeries, and medications. Health insurance in India can also give individuals tax benefits under Section 80D of the Income Tax Act. IRDA safeguards policyholders,
          granting the right to port policies, preserving accumulated benefits. <br /> <br />
          With a healthcare inflation rate of approximately 14% in India, costs nearly double every 5 years.
          Many corporate employees rely on company health insurance, neglecting individual coverage. This leaves them potentially underinsured,
          facing higher premiums or restrictions during job changes. IRDA safeguards policyholders, granting the right to port policies, preserving accumulated benefits.
        </p>
      </div>

    </div>)

  const content2 = (<div className='insurance-section-specific-content'>
    <div className="insurance-social-impact-content-box">
      <h2 className="section-heading insurance-social-impact-head heading-font-size">Life Insuarance</h2>
      <p className="at insurance-social-impact-content">
        Life insurance in India provides financial protection to individuals and their families in case of untimely death.
        It offers a range of policies, including term plans, endowment plans, and ULIPs, catering to diverse needs. Regulatory bodies like IRDAI oversee the industry,
        ensuring transparency and customer protection.
        <br /><br />
        Based on the value of one’s future earnings, a simple way to estimate Life Insurance amount is, for the ages of 18 and 40 it should be 30 times of the annual income, for age 41-50, 20 times of annual income, for age 51-60, 15 times of annual income, and for age 61-65, 10 times of annul income.

      </p>
    </div>
  </div>)

  const content3 = (<div className='insurance-section-specific-content'>
    <div className="insurance-social-impact-content-box">
      <h2 className="section-heading insurance-governance-head heading-font-size">General Insuarance</h2>
      <p className="at insurance-social-impact-content">
        In India, general insurance offers extensive coverage for a multitude of non-life assets, safeguarding against unforeseen circumstances.
        This includes policies for health, motor, travel, home, and commercial properties. Governed by the Insurance Regulatory and Development Authority of India (IRDAI),
        general insurance companies provide financial stability by reimbursing policyholders for any losses or damages incurred.
        <br /> <br />
        Premiums are determined based on factors such as the level of coverage, level of risk, and individual situations. The industry has experienced substantial growth,
        providing a wide range of products to meet the ever-changing needs of consumers. This has fostered a competitive market,
        encouraging innovation and prioritizing customer satisfaction.
      </p>
    </div>
  </div>)

  const contentArr = [content1, content2, content3]
  const handleIndex = (i) => {
    setIndex(i)
  }

  /*-------------form-----------*/

  const [val, setVal] = useState({
    name: '',
    email: '',
    address: '',
    contactNumber: '',
    birthDate: '',
    city: '',
    state: '',
    zip: '',
    insuranceType: 'health'
  })

  const handleChange = (e) => {
    // console.log('CHanging', e.target.name)
    setVal(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let { data, status } = await FETCH(
        'post',
        '/insurance-request',
        '',
        val
      );
      alert(data.message);
      setVal({
        name: '',
        email: '',
        address: '',
        // contactNumber: '',
        birthDate: '',
        city: '',
        state: '',
        zip: '',
        insuranceType: 'health'
      });
    } catch (error) {
      if (error) {
        alert('Check your Internet Connection');
      }
    }
  };

  // handleCOntroller STates 
  const [yt12, setYt12] = useState('https://www.youtube.com/embed/B1QmY7IAE_o?si=ONWxkco-s3MLNTPp');

  // changeControllers 
  const onChangeYt12 = (text) => {
    // console.log('Text is changing', text)
    setYt12(prev => { return text })
  }

  // handleControllers 
  const handleYT12Update = async () => {
    try {
      // console.log('YTLInk MOdifier', ytLinkModifier(yt12))
      setYt12(prev => {
        return ytLinkModifier(yt12)
      })

      let formData = {
        ytId: 'yt12',
        link: ytLinkModifier(yt12)
      }

      let { data, status } = await FETCH(
        'post',
        '/update-yt-link',
        '',
        formData
      )
      alert(data.message)
    } catch (error) {
      if (error) {
        // console.log('Error ***', error)
      }
    }
  }

  // preFetch Calls
  let yt12call = async () => {
    try {
      let { data, status } = await FETCH(
        'get',
        '/get-yt-link',
        'yt12'
      )
      return { data, status }
    } catch (error) {
      if (error) {
        // console.log('Error ***', error)
      }
    }
  }

  useEffect(() => {
    // get the value of yt12 
    yt12call().then(data => {
      if (data.status === 200) {
        setYt12(data.data.data.link)
      } else {
        setYt12('https://www.youtube.com/embed/yydb_gBpEA0?si=ZrmTv17CzgOpxFV_')
      }
    }).catch(
      error => {
        if (error) {
          // console.log('Error ***', error)
        }
      }
    )

  }, [])

  return (
    <div className="insurance-section" id="insurance" style={{ paddingTop: `40px` }}>

      <div className="insurance-section-container-list">
        <ul className='insurance-section-row'>
          <div >
            <li className='list-item1' onClick={() => handleIndex(1)}>Health</li>
            <li className={`list-items3 ${index === 1 ? 'active' : ''}`} onClick={() => handleIndex(1)}></li>
          </div>
          <div>
            <li className='list-item2' onClick={() => handleIndex(2)}>Life</li>
            <li className={`list-items3 ${index === 2 ? 'active' : ''}`} onClick={() => handleIndex(2)}></li>
          </div>
          <div >
            <li className='list-item3' onClick={() => handleIndex(3)}>General</li>
            <li className={`list-items3 ${index === 3 ? 'active' : ''}`} onClick={() => handleIndex(3)}></li>
          </div>

        </ul>
      </div>

      <div className="insurance-section-container-list2">
        <div className="insurance-section-content">{contentArr[index - 1]}</div>
      </div>



      <div className="chooses-video-main">

        {/*YT12*/}

        {!editMode ? (<iframe className="chooses-video" src={yt12}></iframe>) : (<div>
          <iframe className="chooses-video" src={yt12}>
          </iframe>
          <TextinputA label={'Enter Video URL'} placeholder={'Enter Video URL'} onChange={(v) => onChangeYt12(v)} />
          <ButtonA name={'Update'} parentClass={'parent-flex-center'} onClick={handleYT12Update} />
        </div>)}
      </div>


      <div className="insurance-box-container" style={{ marginTop: `40px` }}>
        <div className="insurance-box-main">

          <div className="insurance-text-main">
            <p className="insurance-text">Request a Quote</p>
          </div>

          <form className="in-form" onSubmit={handleSubmit}>
            <label >
              <select className="insurance-form-text" name='insuranceType' onChange={v => handleChange(v)}>
                <option value="health">Health Insurance</option>
                <option value="life">Life Insurance</option>
                <option value="work">Work Insurance</option>
              </select>
            </label>

            <br />
            <input
              value={val.name}
              name='name'
              onChange={handleChange}
              placeholder="Full Name"
              className="insurance-form-text1"
              required
            />
            <input
              name='email'
              type='email'
              value={val.email}
              onChange={handleChange}
              className="insurance-form-text1"
              placeholder="Email"
            />

            <input
              name='address'
              value={val.address}
              onChange={handleChange}
              className="insurance-form-text1"
              placeholder="Address"
            />
            <input
              type="tel"
              name="contactNumber"
              maxLength={10}
              value={val.contactNumber}
              onChange={handleChange}
              className="insurance-form-text1"
              placeholder="Contact Number"
              pattern="[0-9]{10}"
              required
            />
            <input
              type="date"
              name='birthDate'
              value={val.birthDate}
              onChange={handleChange}
              className="insurance-form-text1"
              placeholder="dd-mm-yyyy"
            />
            <div className="insurance-input-text2">
              <input
                type="text"
                name='city'
                value={val.city}
                onChange={handleChange}
                className="insurance-form-text2"
                placeholder="City"
              />
              <input
                type="text"
                name='state'
                value={val.state}
                onChange={handleChange}
                className="insurance-form-text2"
                placeholder="State/Province"
              />
            </div>
            <input
              type="number"
              maxLength={6}
              name='zip'
              value={val.zip}
              onChange={handleChange}
              className="insurance-form-text1"
              placeholder="Zip/Postal coder"
            />
            <div className='Insurance-btn'>
              <ButtonA name={'submit'} parentClass={'Insurance-submit'} />

            </div>
          </form>
        </div>
      </div>

    </div>
  )
}

export default Insurance