import React from 'react';
import styles from '../assets/css/atoms/Button.module.css';

const ButtonA = (props) => {
  return (
    <div className={props?.parentClass} >
      <button className={styles.btn} {...props}>
        {props.name}
      </button>
    </div>
  );
}

export default ButtonA;
