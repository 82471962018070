import React, { useState,useEffect } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import '../assets/css/navigation/navbar.css';
import { Link, useLocation } from 'react-router-dom';
import '@fortawesome/fontawesome-svg-core/styles.css';
import logo from '../assets/images/logo.png'
import { scrollToTop } from '../utils/scrollToTop';

const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const location = useLocation();

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const closeNav = () => {
    setIsNavOpen(false);
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isNavOpen && !event.target.closest('.navbar')) {
        closeNav();
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isNavOpen]);

  const handleNavLinkClick = () => {
    closeNav();
  };

  return (
    <nav className={`navbar ${isNavOpen ? 'nav-open' : ''}`} >
      <div className="navbar-logo">
        <Link to="/home"><img src={logo} className="head-img-1" alt={"logo"} /></Link>
      </div>

      <div className="hamburger-icon" >
        {/* <FontAwesomeIcon icon={isNavOpen ? faTimes : faBars} />*/}
        <input type="checkbox" id="menu_checkbox" onClick={toggleNav} />
        <label for="menu_checkbox" className='label'>
          <div></div>
          <div></div>
          <div></div>
        </label>
      </div>


      <div className={`nav-links ${isNavOpen ? 'open' : ''}`} >
        <ul className="navbar-menu">
          <li className={location.pathname === '/' ? 'active' : ''}>
            <Link className="linkn1" to="/" onClick={() => { scrollToTop(); handleNavLinkClick(); }}>Home</Link>
          </li>

          <li className={location.pathname === '/about' ? 'active' : ''}>
            <Link className="linkn1" to="/about-us" onClick={() => { scrollToTop(); handleNavLinkClick(); }}>About us</Link>
          </li>

          <li className={location.pathname === '/Home-Loan' ? 'active' : ''}>
            <div className="Down-main">
              <Link className="linkn1" to='/Home-Loan' onClick={() => { scrollToTop(); handleNavLinkClick(); }}>Home Loan</Link>
              <FaChevronDown className='Down-arrow' style={{ marginLeft: '10px', fontSize: '0.8em' }} />
            </div>

            {/* Add the dropdown content for "About Us" */}
            <div className="dropdown-content">
              <li className={location.pathname === '/homeLoancalculator' ? 'active' : ''}>
                <Link className="linkn1" to="/homeLoancalculator" onClick={() => { scrollToTop(); handleNavLinkClick(); }}>Home Loan Calculator</Link>
              </li>
              <li className={location.pathname === '/eligibilitycalculator' ? 'active' : ''}>
                <Link className="linkn1" to="/eligibilitycalculator" onClick={() => { scrollToTop(); handleNavLinkClick(); }}>Eligibility Calculator</Link>
              </li>
              <hr />
            </div>
          </li>

          <li className={location.pathname === '/homeloan' ? 'active' : ''}>
            <div className="Down-main">
              <Link className="linkn1" to='#'>Finance</Link>
              <FaChevronDown className='Down-arrow' style={{ marginLeft: '10px', fontSize: '0.8em' }} />
            </div>

            {/* Add the dropdown content for "About Us" */}
            <div className="dropdown-content">
              <li className={location.pathname === '/Personal-Business-Loan' ? 'active' : ''}>
                <Link className="linkn1" to="/Personal-Business-Loan" onClick={() => { scrollToTop(); handleNavLinkClick(); }}>Personal & Business Loan</Link>
              </li>
              <li className={location.pathname === '/Agriculture-Vehical-Loan' ? 'active' : ''}>
                <Link className="linkn1" to="/Agriculture-Vehical-Loan" onClick={() => { scrollToTop(); handleNavLinkClick(); }}>Agriculture & Vehicle Loan</Link>
              </li>
              <li className={location.pathname === '/Education-Loan' ? 'active' : ''}>
                <Link className="linkn1" to="/Education-Loan" onClick={() => { scrollToTop(); handleNavLinkClick(); }}>Education Loan</Link>
              </li>
            </div>
          </li>

          <li className={location.pathname === '/realestateinfo' ? 'active' : ''}>
            <div className="Down-main">
              <Link className="linkn1" to='/realestateinfo' onClick={() => { scrollToTop(); handleNavLinkClick(); }}>Real Estate</Link>
              <FaChevronDown className='Down-arrow' style={{ marginLeft: '10px', fontSize: '0.8em' }} />
            </div>

            {/* Add the dropdown content for "About Us" */}
            <div className="dropdown-content">
              <li className={location.pathname === '/property1' ? 'active' : ''}>
                <Link className="linkn1" to="/property-projects" onClick={() => { scrollToTop(); handleNavLinkClick(); }}>Our Property</Link>
              </li>
              
            </div>
          </li>

          <li className={location.pathname === '/insurance' ? 'active' : ''}>
            <Link className="linkn1" to="/insurance" onClick={() => { scrollToTop(); handleNavLinkClick(); }}>Insurance</Link>
          </li>

          <li className={location.pathname === '/investmentinfo' ? 'active' : ''}>
            <Link className="linkn1" to="/investmentinfo" onClick={() => { scrollToTop(); handleNavLinkClick(); }}>Investment</Link>
          </li>


          <li className={location.pathname === '/contact' ? 'active' : ''}>
            <Link className="linkn1" to="/contact" onClick={() => { scrollToTop(); handleNavLinkClick(); }}>Contact us</Link>

          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;