import React, { useState } from 'react';
import contact from '../../assets/images/Slider1.jpg'
import "../../assets/css/containers/contact.css"
import { Link } from 'react-router-dom';
import { TfiEmail } from 'react-icons/tfi';
import { FiFacebook } from 'react-icons/fi';
import { FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import { BsGeoAlt, BsTelephone } from 'react-icons/bs';
import ButtonA from '../../atoms/ButtonA';
import { FETCH } from '../../services/Fetch.tsx';

const Contact = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    address: '',
    contactNumber: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let { data, status } = await FETCH('post', '/contact-request', '', formData);
      alert(data.message);

      // Reset the form data after successful submission
      setFormData({
        fullName: '',
        email: '',
        address: '',
        contactNumber: '',
        message: '',
      });
    } catch (error) {
      alert('Check Your Internet Connection');
    }
  };

  return (
    <section className="section  contact-div">
      <div className="con-container">
        <div className="contact-heading">
          <p className="section-heading con-text-heding-main1">Contact Us</p>
        </div>

        <div className="contact-container2">
          <div className="con-all">
            <div className="section-inner-heading con-all-heading">Get In Touch</div>
            <div className="con-all-subheading">OFFICE ADDRESS</div>
            <div className="con-listls-1">
              <ul className="con-contactUs-link-list">
                <div className="con-adde-main">
                  <BsGeoAlt className="con-icon3 con-icons-1" />
                  <li className="con-text1">
                    A-205, Second Floor, KPCT <br />
                    Mall, Besides Inamdar Hospital,<br />
                    Fatima Nagar, Pune- <br />
                    411040. Maharashtra
                  </li>
                </div>

                <div className="con-adde-main">
                  <BsTelephone className="con-icon3" />
                  <li className="con-text1">+91 9518753980</li>
                </div>

                <div className="con-adde-main">
                  <TfiEmail className="con-icon3 con-icons-1 " />
                  <li className="con-text1">info@finainv.in <br />finasourcein@gmail.com</li>
                </div>
              </ul>
            </div>
            <div className="con-menu-socialLink">
              <div className="con-social-headings con-head">Social Links</div>
              <div>
                <ul className="con-social-link-list">
                  <li>
                    <Link to="https://www.facebook.com/profile.php?id=61551843677173">
                      <FiFacebook className="con-logo1" />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.linkedin.com/in/ameet-dutt-3a428b7/">
                      <FaLinkedinIn className="con-logo1" />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.youtube.com/channel/UCQcjTv0oeNCFS39zuVEHhaQ">
                      <FaYoutube className="con-logo1" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="con-form">
            <form onSubmit={handleSubmit}>
              <label className="con-form-label">
                Full Name:
                <input
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                />
              </label>
              <br />

              <label className="con-form-label">
                Email:
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </label>
              <br />

              <label className="con-form-label">
                Address:
                <textarea
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
              </label>
              <br />

              <label className="con-form-label">
                Contact Number:
                <input
                  type="tel"
                  name="contactNumber"
                  maxLength={10}
                  value={formData.contactNumber}
                  onChange={handleChange}
                  pattern="[0-9]{10}"
                  required
                />
              </label>
              <br />

              <label className="con-form-label">
                Message:
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </label>
              <br />
              <div className="contact-btn">
                <ButtonA name="Submit" />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="box-map mp-1">
        <iframe
          className="google-m-2"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.556886404955!2d73.89794107496243!3d18.503720132586594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c1cf5f3b250f%3A0x701bafe732d91d4!2sKPCT%20MALL%2C%20Vitthal%20Rao%20Shivarkar%20Rd%2C%20Fatima%20Nagar%2C%20Wanowrie%2C%20Pune%2C%20Maharashtra%20411040!5e0!3m2!1sen!2sin!4v1703572316827!5m2!1sen!2sin"
        ></iframe>
      </div>
    </section>
  );
};

export default Contact;
