import React, { createContext, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Screen from "./components/screen/Screen";
import Home from "./components/container/home/Home";
import About from "./components/container/about/About";
import LoanAV from "./components/container/Finance/LoanAV";
import LoanEduction from "./components/container/Finance/LoanEduction";
import LoanPerBui from "./components/container/Finance/LoanPerBui";
import HomeLoan1 from "./components/container/homeloan/HomeLoan1";
import HomeLoanCalculator from "./components/container/homeloan/HomeLoanCal";
import EligibilityCalculator from "./components/container/homeloan/EligibilityCal";
import Insurance from "./components/container/insurance/Insurance";
import Investmentinfo from "./components/container/investment/Investmentinfo";
import Realestateinfo from "./components/container/realestate/Realestateinfo";
import Contact from "./components/container/contact/Contact";
import Privacy from "./components/includes/Privacy";
import Terms from "./components/includes/Terms";
import LoanForm from "./components/common/FromNo1";
import AdminLogin from "./components/admin/AdminLogin";
import './App.css'
import AddProjectForm from "./components/molecules/AddProjectForm";
import PaginationComponent from "./components/molecules/Pagination";

export const Context = createContext();

function App() {
  // Define contextValue with the appropriate value or context
  const [editMode , setEditMode] = useState(false)
  
  const contextValue = {
    editMode,
    setEditMode
  }

  return (
    <Context.Provider value={contextValue}>
      <BrowserRouter>
        <Screen>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/Agriculture-Vehical-Loan" element={<LoanAV/>}/>
            <Route path="/Education-Loan" element={<LoanEduction/>} />
            <Route path="/Personal-Business-Loan" element={<LoanPerBui />} />
            <Route path="/Home-Loan" element={<HomeLoan1/>} />
            <Route path="/homeLoancalculator" element={<HomeLoanCalculator/>} />
            <Route path="/eligibilitycalculator" element={<EligibilityCalculator/>} />
            <Route path="/insurance" element={<Insurance />} />
            <Route path="/investmentinfo" element={<Investmentinfo />} />
            <Route path="/realestateinfo" element={<Realestateinfo />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route exact path="/loanform" element={<LoanForm />} />
            <Route exact path="/admin" element={<AdminLogin />} />
            <Route exact path="/property-projects" element={<PaginationComponent />} />
            {editMode && <Route exact path="/new-project" element={<AddProjectForm />} />}
          </Routes>
        </Screen>
      </BrowserRouter>
    </Context.Provider>
  );
}

export default App;
