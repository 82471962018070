import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import Imgslider from '../../includes/Slider';  
import '../../assets/css/includes/slider.css'
// import slider1 from '../../assets/images/Slider1.jpg';
// import slider2 from '../../assets/images/Slider2.jpeg';
// import slider3 from '../../assets/images/Slider3.jpeg';
// import slider4 from '../../assets/images/Slider4.jpeg';
import CounterSection from '../../includes/CounterSection';
import Aboutimg1 from '../../assets/images/AboutUsWeb.jpg';
import Aboutimg2 from '../../assets/images/AboutUsWeb1.jpg';
import images1 from '../../assets/images/Slider1.jpg';
import featurelogo1 from '../../assets/images/logo.png';
import ButtonA from "../../atoms/ButtonA";
import "../../assets/css/containers/home.css";
import ServicesCard from '../../common/ServicesCard';
// import testimonials1 from '../../assets/images/logo/mayur.png';
import ListItem from '../../atoms/ListItem';
import { Context } from '../../../App';
import TextinputA from '../../atoms/TextinputA';
import { ytLinkModifier } from '../../utils/utils.tsx';
import { FETCH } from '../../services/Fetch.tsx';
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../utils/scrollToTop.js';

import testimonials1 from '../../assets/images/logo/J1.jpg';
import testimonials2 from '../../assets/images/logo/M1.jpg';
import testimonials3 from '../../assets/images/logo/R11.jpg';
import testimonials4 from '../../assets/images/logo/P11.jpg';



import Homeimages1 from '../../assets/images/Cardsimages/homeloan.jpg';
import Homeimages2 from '../../assets/images/Cardsimages/Real-Estate.jpg';
import Homeimages3 from '../../assets/images/Cardsimages/Business Loans.jpg';
import Homeimages4 from '../../assets/images/Cardsimages/Personal Loans.jpeg';
import Homeimages5 from '../../assets/images/Cardsimages/Education Loans.jpg';
import Homeimages6 from '../../assets/images/Cardsimages/Vehicle Loans.jpg';
import Homeimages7 from '../../assets/images/Cardsimages/Life Insurance.jpg';
import Homeimages8 from '../../assets/images/Cardsimages/Health Insurance.jpg';
import Homeimages9 from '../../assets/images/Cardsimages/Wealth Management.jpeg';

const Home = () => {
  
  const { editMode, setEditMode } = useContext(Context)

  const contantSlider = (<div className='section-heading slider-data'>
    Finasource Innovations
    <hr style={{
      height:`5px`,
      width:`25vw`,
      color: `#16427f`,
      backgroundColor:`#16427f`
    }}/>
    <div className='section-inner-heading' style={{color:`white`,display:`flex`}}>
    Financial & Real Estate Solutions</div>
  </div>)

  const initialCounters = [
    { label: 'Loan Amount Distribution', label2: 'Cr.+', count: 0 },
    { label: 'Projects Completed', label2: '+', count: 0 },
    { label: 'Satisfied Clients', label2: '+', count: 0 },
    { label: 'Years of Experience', label2: '+', count: 0 },
    { label: 'Bank & NBFC Tie-Ups', label2: '+', count: 0 }
  ];

  const targetValues = [
    initialCounters[0].count + 250,
    initialCounters[1].count + 500,
    initialCounters[2].count + 1000,
    initialCounters[3].count + 20,
    initialCounters[4].count + 60,
  ];

  // handleCOntroller STates 
  const [yt1, setYt1] = useState('https://www.youtube.com/embed/yydb_gBpEA0?si=ZrmTv17CzgOpxFV_');

  // changeControllers 
  const onChangeYt1 = (text)=>{
    // console.log('Text is changing',text)
    setYt1(prev => {return text})
  }

  // handleControllers 
  const handleYT1Update = async()=>{
   try {
     // console.log('YTLInk MOdifier',ytLinkModifier(yt1))
     setYt1(prev=>{
       return ytLinkModifier(yt1)
     })
 
     let formData = {
       ytId : 'yt1',
       link : ytLinkModifier(yt1)
     }
 
     let {data , status} = await FETCH(
       'post',
       '/update-yt-link',
       '',
       formData
     )
     alert(data.message)
   } catch (error) {
      if(error){
        // console.log('Error ***',error)
      }
   }
  }

  // preFetch Calls
  let yt1call = async ()=>{
   try {
     let {data , status} = await FETCH(
       'get',
       '/get-yt-link',
       'yt1'
     )
     return {data , status}
   } catch (error) {
    if(error){
      // console.log('Error ***',error)
    }
   }
  }

  useEffect(()=>{
    // get the value of yt1 
    yt1call().then(data=>{
      if(data.status===200){
        setYt1(data.data.data.link)
      }else{
        setYt1('https://www.youtube.com/embed/yydb_gBpEA0?si=ZrmTv17CzgOpxFV_')
      }
    }).catch(
      error =>{
        if(error){
          // console.log('Error ***',error)
        }
      }
    )

  }, [])

  return (

    <div className="home-container">
      <div className="home-slider slider-shadow">
      <Imgslider sliderText={contantSlider}/>
      </div>

      <div className="home-counters">
        <div className='home-sections-main'>
          <CounterSection
            initialCounters={initialCounters}
            targetValues={targetValues}
            intervalDuration={100}
          />

          <div className="about-main-container">

            <div className="about-img-fluid">
              <img src={Aboutimg1} className="about-img-1" alt={"logo"} />
              <img src={Aboutimg2} className="about-img-2" alt={"logo"} />
            </div>

            <div className="about-text-containermain">
              <div className="">
                <p className="section-heading">About Us</p>
              </div>
              <p className="about-text-heding">We Provide better for you</p>
              <p className="at about-text-paregraph">Finasource Innovations excels as a comprehensive solution provider in the financial landscape,
                specializing in Home Loans, Real Estate, Business Loans, Personal Loans, Education Loans, Vehicle Loans, Life Insurance, Health Insurance, and Wealth Management.
                Our expertise spans diverse sectors, offering tailored solutions to individuals and businesses. With a commitment to innovation, transparency, and client success,
                Finasource transforms the financial experience. Whether navigating real estate transactions, securing loans, or managing insurance needs, Finasource stands as a trusted partner,
                providing expert guidance and fostering financial well-being across various domains. Explore our offerings at finasourceinnovations.com for a holistic approach to financial prosperity.</p>

              <Link to="/about-us" onClick={scrollToTop}><ButtonA name={`Read More`}></ButtonA></Link>
            </div>

          </div>
        </div>
      </div>

      <div className="bg-section-color">
        <div className="services-text-main">
          <div className="services-text-heding-main">
            <p className="section-heading">Our Services</p>
          </div>
          <p className="services-text-heding">We Provide better for you</p>
          <p className="services-text-paregraph at">Finasource Innovations provides a comprehensive array of financial services to meet diverse needs:
            With a commitment to excellence and client satisfaction, Finasource Innovations stands as a versatile partner,
            addressing a spectrum of financial needs across different sectors.</p>
        </div>

        <div className="service-cards-main">

          <div className="service-cards">
            <ServicesCard
            imageSrc={Homeimages1}
              title="Home Loan"
              description="Facilitating home ownership with flexible financing options."
              ButtonName = "Explore"
              navigation = "/Home-Loan"
              sectionId= '#homeLoan'
            />
            <ServicesCard
            imageSrc={Homeimages2}
              title="Real Estate Services"
              description="Navigating and streamlining real estate transactions for clients."
              ButtonName = "Explore"
              navigation = "/realestateinfo"
              sectionId= '#realstateinfo'
            />
            <ServicesCard
            imageSrc={Homeimages3}
              title="Business Loan"
              description="Supporting business growth through tailored loan solutions."
              ButtonName = "Explore"
              navigation = "/Personal-Business-Loan"
              sectionId= '#businessloan'
          
            />
            <ServicesCard
            imageSrc={Homeimages4}
              title="Personal Loan"
              description="Meeting individual financial requirements with personalized lending."
              ButtonName = "Explore"
              navigation = "/Personal-Business-Loan"
              sectionId= '#personalloan'
            />
            <ServicesCard
            imageSrc={Homeimages5}
              title="Education Loan"
              description="Meeting individual financial requirements with personalized lending."
              ButtonName = "Explore"
              navigation = "/Education-Loan"
              sectionId= '#educationloan'
            />
            <ServicesCard
            imageSrc={Homeimages6}
              title="Vehicle Loan"
              description="Simplifying vehicle acquisition through financing solutions."
              ButtonName = "Explore"
              navigation = "/Agriculture-Vehical-Loan"
              sectionId= '#vehicle'
            />
            <ServicesCard
            imageSrc={Homeimages7}
              title="Life Insurance"
              description="offering protection and financial security through life insurance products."
              ButtonName = "Explore"
              navigation = "/insurance"
              sectionId= '#insurance'
            />
            <ServicesCard
            imageSrc={Homeimages8}
              title="Health Insurance"
              description="Offering protection and financial security through life insurance products."
              ButtonName = "Explore"
              navigation = "/insurance"
              sectionId= '#insurance'
            />
            <ServicesCard
            imageSrc={Homeimages9}
              title="Wealth Management"
              description="Expert guidance for optimizing and growing financial portfolios."
              ButtonName = "Explore"
              navigation = "/investmentinfo"
              sectionId= '#investmentinfo'
            />
          </div>

          <p className="at chooses-text-paregraph">With a commitment to excellence and client satisfaction, Finasource Innovations stands as a versatile partner, addressing a spectrum of financial needs across different sectors.
        </p>

        </div>
      </div>

      <div className="chooses-container-main">
        <div className="services-text-main">
          <div className="chooses-text-heding-main">
            <p className="section-heading" style={{ margin: 20 }}>Why Choose us</p>
          </div>
          <p className="services-text-paregraph" style={{ marginBottom: 30 }}>Choosing Finasource Innovations comes with several compelling advantages.</p>
        </div>

        <div className="chooses-video-main">

          {/*YT1*/}

          {!editMode ? (<iframe className="chooses-video" src={yt1}></iframe>) : (<div>
            <iframe className="chooses-video" src={yt1}>
            </iframe>
            <TextinputA label={'Enter Video URL'} placeholder={'Enter Video URL'} onChange={(v) => onChangeYt1(v)} />
            <ButtonA name={'Update'} parentClass={'parent-flex-center'} onClick={handleYT1Update} />
          </div>)}
        </div>



        <div className="chooses-text-main" style={{marginTop:`40px`}}>

        <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`Comprehensive Expertise`}
            bodyMessage={` Finasource excels in a wide range of services like Real Estate Solutions and financial services, from loans to insurance and wealth management, providing holistic solutions.`}
          />

          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`Innovation and Technology`}
            bodyMessage={`Leveraging cutting-edge technology and innovative approaches,
          Finasource ensures efficiency, transparency, and forward-looking solutions.`}
          />

          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`Tailored Solutions`}
            bodyMessage={`The company understands that financial needs vary,
          offering personalized and flexible solutions to meet the unique requirements of individuals and businesses.`}
          />

          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`Client-Centric Approach`}
            bodyMessage={`Finasource prioritizes client satisfaction,
          providing attentive customer service and building long-term relationships based on trust and reliability.`}
          />

          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`Transparent Processes`}
            bodyMessage={`The company maintains transparency in its operations,
          ensuring clients have a clear understanding of the services and products they choose.`}
          />

          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`Industry Experience`}
            bodyMessage={` With expertise in diverse financial sectors,
          Finasource brings seasoned professionals who understand the intricacies of the market, guiding clients toward informed decisions.`}
          />

          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`Commitment to Excellence`}
            bodyMessage={` Finasource is committed to delivering excellence in every aspect of its services,
          striving for the financial success and well-being of its clients.`}
          />

          <p className="at chooses-text-paregraph">By choosing Finasource Innovations, clients gain a partner dedicated to navigating
            the complexities of the financial landscape while providing tailored solutions for their specific needs.
          </p>
        </div>
      </div>

      <div className="feature-text-main bg-section-color">
        <div className="services-text-main">
          <div className="feature-text-heding-main">
            <p className="section-heading">Our Features</p>
          </div>
          <p className="services-text-paregraph feat-p">Finasource Innovations offers a range of features that distinguish it as a comprehensive and client-centric financial service provider :</p>
        </div>

        <div className="feature-box-main-f1">
          <div className="feature-box-main" >
            <div className="feature-box" >
              <img src={featurelogo1} className="feature-logo" alt={"logo"} />
              <p className="feature-heding-text">Diverse Financial Services</p>
              <p className="feature-heding-textp">Covers Home Loans, Real Estate, Business Loans, Personal Loans, Education Loans,
                Vehicle Loans, Life Insurance, Health Insurance, and Wealth Management.</p>
            </div>

            <div className="feature-box" >
              <img src={featurelogo1} className="feature-logo" alt={"logo"} />
              <p className="feature-heding-text">Innovative Technology Integration</p>
              <p className="feature-heding-textp">Harnesses cutting-edge technology and data analytics
                for efficient and forward-looking financial solutions.</p>
            </div>
            <div className="feature-box" >
              <img src={featurelogo1} className="feature-logo" alt={"logo"} />
              <p className="feature-heding-text">Tailored Solutions</p>
              <p className="feature-heding-textp">Provides personalized financial solutions that
                cater to the unique needs of individuals and businesses</p>
            </div>

            <div className="feature-box" >
              <img src={featurelogo1} className="feature-logo" alt={"logo"} />
              <p className="feature-heding-text">Transparent Processes</p>
              <p className="feature-heding-textp">Maintains transparency in all operations,
                ensuring clients have a clear understanding of services, terms, and conditions.</p>
            </div>
            <div className="feature-box" >
              <img src={featurelogo1} className="feature-logo" alt={"logo"} />
              <p className="feature-heding-text">Experienced Professionals</p>
              <p className="feature-heding-textp">Boasts a team of industry experts with extensive experience
                in various financial sectors, offering valuable insights and guidance.</p>
            </div>
            <div className="feature-box" >
              <img src={featurelogo1} className="feature-logo" alt={"logo"} />
              <p className="feature-heding-text">Client-Focused Approach</p>
              <p className="feature-heding-textp">Prioritizes client satisfaction,
                offering attentive customer service and building long-lasting relationships based on trust and reliability.</p>
            </div>

            <div className="feature-box" >
              <img src={featurelogo1} className="feature-logo" alt={"logo"} />
              <p className="feature-heding-text">Efficiency and Timeliness</p>
              <p className="feature-heding-textp">Emphasizes prompt and efficient service delivery,
                recognizing the importance of timely financial solutions for clients.</p>
            </div>
            <div className="feature-box" >
              <img src={featurelogo1} className="feature-logo" alt={"logo"} />
              <p className="feature-heding-text">Holistic Financial Planning</p>
              <p className="feature-heding-textp">Provides comprehensive wealth management services,
                helping clients optimize and grow their financial portfolios.</p>
            </div>
            <div className="feature-box" >
              <img src={featurelogo1} className="feature-logo" alt={"logo"} />
              <p className="feature-heding-text">Commitment to Excellence</p>
              <p className="feature-heding-textp">Demonstrates a commitment to excellence in all aspects,
                striving for the financial success and well-being of clients.</p>
            </div>
          </div>
          <p className="at chooses-text-paregraph parghph-2">By encompassing these features, Finasource Innovations aims to stand out as a versatile,
            technology-driven, and client-focused partner in the financial services landscape.</p>
        </div>
      </div>


      <div className="testimonials-text-main">
        <div className="testimonials-text-heding-main">
          <p className="section-heading">Testimonials</p>
        </div>


        <div className="testimonials-box-main" >
          <div className="testimonials-box" >
          <img src={testimonials1} className="testimonials-logo" alt={"logo"} />
          <p className="testimonials-heding-text">Manish Jain</p>
            <p className="testimonials-heding-textp">"Finasource transformed my home-buying experience with their seamless Home Loan process.
              Their expertise and transparency made them my go-to financial partner. "</p>
          </div>

          <div className="testimonials-box" >
          <img src={testimonials2} className="testimonials-logo" alt={"logo"} />
          <p className="testimonials-heding-text">Vijay B</p>
            <p className="testimonials-heding-textp">"Exceptional service from Finasource! Their tailored solutions and commitment
              to client satisfaction made securing a Business Loan a breeze. Highly recommended for financial needs. "</p>
          </div>

          <div className="testimonials-box" >
          <img src={testimonials3} className="testimonials-logo" alt={"logo"} />
          <p className="testimonials-heding-text">Mr Harmeet S.</p>
            <p className="testimonials-heding-textp">"Finasource's Wealth Management expertise exceeded my expectations. Their insightful guidance and innovative
              approach helped me optimize my financial portfolio for long-term success. Truly exceptional service! "</p>
          </div>

          <div className="testimonials-box" >
          <img src={testimonials4} className="testimonials-logo" alt={"logo"} />
          <p className="testimonials-heding-text">Amit S</p>
            <p className="testimonials-heding-textp">"Finasource Innovations made my Pune real estate journey effortless. Their expertise and seamless processes simplified transactions,
              ensuring I found my dream property hassle-free. Highly recommend their exceptional services! "</p>
          </div>
        </div>

      </div>


    </div>

  )
}

export default Home