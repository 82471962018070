import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../assets/css/includes/CounterSection.css'; // Assuming you have a CSS file with the provided styles



const CounterSection = ({ initialCounters, targetValues, intervalDuration }) => {
    const [counters, setCounters] = useState(initialCounters);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCounters((prevCounters) =>
          prevCounters.map((counter, index) => ({
            ...counter,
            count: counter.count < targetValues[index] ? counter.count + 1 : counter.count,
          }))
        );
  
        // Check if all counters have reached their target values
        const allCountersReachedTarget = counters.every(
          (counter, index) => counter.count >= targetValues[index]
        );
  
        // If all counters reached target, clear the interval
        if (allCountersReachedTarget) {
          clearInterval(interval);
        }
      }, 500);
  
      return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [initialCounters, targetValues ]);
  
    return (
      <div>
        <div className="row">
          {counters.map((counter, index) => (
            <div className="column flexy-box" key={index}>
              <div className="card">
              <h3 className="text-no">
              {counter.count} {counter.label2}
              </h3>
                <p className="text-n1">{counter.label}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  
  CounterSection.propTypes = {
    initialCounters: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        label2: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
      })
    ).isRequired,
    targetValues: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
    intervalDuration: PropTypes.number.isRequired,
  };
  
  export default CounterSection;