import React, { useState, useEffect, useLayoutEffect, useContext } from 'react'
import LoanImage from '../../assets/images/Slider2.jpeg'
import Images1 from '../../assets/images/lona/eductionloan1.jpg';
import Images2 from '../../assets/images/lona/eductionaloan2.jpg';
import "../../assets/css/comman/homeloan.css";
import ButtonA from "../../atoms/ButtonA";
import TextinputA from '../../atoms/TextinputA';
import ListItem from '../../atoms/ListItem';
import { Context } from '../../../App';
import { ytLinkModifier } from '../../utils/utils.tsx';
import { FETCH } from '../../services/Fetch.tsx';
import { Link } from "react-router-dom";
import { scrollToTop } from '../../utils/scrollToTop';

const LoanPerBui = () => {
  // YT5 YT6
  const { editMode, setEditMode } = useContext(Context)
  // handleCOntroller STates 
  const [yt5, setYt5] = useState('https://www.youtube.com/embed/yydb_gBpEA0?si=ZrmTv17CzgOpxFV_');
  const [yt6, setYt6] = useState('https://www.youtube.com/embed/yydb_gBpEA0?si=ZrmTv17CzgOpxFV_');

  // changeControllers 
  const onChangeYt5 = (text) => {
    // console.log('Text is changing', text)
    setYt5(prev => { return text })
  }
  const onChangeYt6 = (text) => {
    // console.log('Text is changing', text)
    setYt6(prev => { return text })
  }

  // handleControllers 
  const handleYT5Update = async () => {
    try {
      // console.log('YTLInk MOdifier', ytLinkModifier(yt5))
      setYt5(prev => {
        return ytLinkModifier(yt5)
      })

      let formData = {
        ytId: 'yt5',
        link: ytLinkModifier(yt5)
      }

      let { data, status } = await FETCH(
        'post',
        '/update-yt-link',
        '',
        formData
      )
      alert(data.message)
    } catch (error) {
      if (error) {
        // console.log('Error ***', error)
      }
    }
  }
  const handleYT6Update = async () => {
    try {
      // console.log('YTLInk MOdifier', ytLinkModifier(yt6))
      setYt6(prev => {
        return ytLinkModifier(yt6)
      })

      let formData = {
        ytId: 'yt6',
        link: ytLinkModifier(yt6)
      }

      let { data, status } = await FETCH(
        'post',
        '/update-yt-link',
        '',
        formData
      )
      alert(data.message)
    } catch (error) {
      if (error) {
        // console.log('Error ***', error)
      }
    }
  }

  // preFetch Calls
  let yt5call = async () => {
    try {
      let { data, status } = await FETCH(
        'get',
        '/get-yt-link',
        'yt5'
      )
      return { data, status }
    } catch (error) {
      if (error) {
        // console.log('Error ***', error)
      }
    }
  }
  let yt6call = async () => {
    try {
      let { data, status } = await FETCH(
        'get',
        '/get-yt-link',
        'yt6'
      )
      return { data, status }
    } catch (error) {
      if (error) {
        // console.log('Error ***', error)
      }
    }
  }

  useEffect(() => {
    // get the value of yt5 yt6
    yt5call()
      .then(data => {
        if (data.status === 200) {
          setYt5(data.data.data.link)
        } else {
          setYt5('https://www.youtube.com/embed/8WiDwcchrd8?si=XfuePfrDx5v96kkC')
        }
      })
      .catch(error => {
        if (error) {
          // console.log('Error ***', error)
        }
      })
    yt6call()
      .then(data => {
        if (data.status === 200) {
          setYt6(data.data.data.link)
        } else {
          setYt6('https://www.youtube.com/embed/8WiDwcchrd8?si=XfuePfrDx5v96kkC')
        }
      })
      .catch(error => {
        if (error) {
          // console.log('Error ***', error)
        }
      })

  }, [])

  return (
    <div>
      <div id="personalloan" className="container">

        <div className="personal-img-fluid loan-img-fluid">
          <h2></h2>
        </div>

        <p className="section-heading" style={{ margin: 20, textAlign: `center` }}>Personal Loan</p>




        <p className="font-size at ap-para">A personal loan is a type of unsecured loan that individuals can borrow from a bank, credit union, or online lender. It provides a lump sum of money for various personal expenses, such as medical bills, debt consolidation, or home improvement. Repayment terms and interest rates vary based on the borrower's creditworthiness.</p>

        <div className="at home-criteria-part" style={{ lineHeight: `0.5` }}>
          <div className='section-inner-heading'>Eligibility Criteria:</div>
          <div className="font-size at ap-para">Eligibility for a personal loan depends on factors like credit score, income, employment stability, and debt-to-income ratio. Lenders assess these to determine the borrower's creditworthiness for approval.</div>
          </div>

        <p className="font-size at ap-para">Finasource Innovations specializes in facilitating Personal Loans through established banks and NBFCs. Leveraging a wide network, they connect borrowers with competitive interest rates, flexible repayment options, and swift approval processes. Our expertise ensures seamless access to financial solutions, meeting diverse personal needs with trusted lending institutions.</p>
        <Link to="/loanform" onClick={scrollToTop}><ButtonA name={`Apply Now`}></ButtonA></Link>
        <div className="chooses-video-main">

          {/*YT5*/}

          {!editMode ? (<iframe className="chooses-video" src={yt5}></iframe>) : (<div>
            <iframe className="chooses-video" src={yt5}>
            </iframe>
            <TextinputA label={'Enter Video URL'} placeholder={'Enter Video URL'} onChange={(v) => onChangeYt5(v)} />
            <ButtonA name={'Update'} parentClass={'parent-flex-center'} onClick={handleYT5Update} />
          </div>)}
        </div>

      </div>
      <br /> <br />

      <div id="businessloan" className="container bg-section-color">
        <div className="business-img-fluid loan-img-fluid">
          <h2></h2>
        </div>

        <p className="section-heading business " style={{ margin: 20, textAlign: `center` }}>Business Loan</p>
        <p className="font-size at ap-para">A business loan is a financial arrangement where a lender provides capital to a business entity, enabling it to fund operations, expansion, or other specific needs. The borrower agrees to repay the loan amount with interest over an agreed-upon period, helping businesses access necessary funds for growth and sustainability.</p>

        <div className="at home-criteria-part" style={{ lineHeight: `0.5` }}>
          <div className='section-inner-heading'>Eligibility Criteria:</div>
            <div className="font-size at ap-para">Eligibility for a business loan typically depends on factors like credit score, business revenue, financial stability, industry type, and the borrower's repayment capacity.</div>
        </div>
        <div className="at home-criteria-part" style={{ lineHeight: `0.5` }}>
          <div className='section-inner-heading'>Our Offerings:</div>
            <div className="font-size at ap-para">Finasource Innovations provides tailored business loans through renowned banks and NBFCs, offering competitive interest rates, flexible repayment terms, and quick approval, empowering businesses to thrive and expand.</div>
            
        </div>

        <Link to="/loanform" onClick={scrollToTop}><ButtonA name={`Apply Now`}></ButtonA></Link>

        <div className="chooses-video-main">

          {/*YT6*/}

          {!editMode ? (<iframe className="chooses-video" src={yt6}></iframe>) : (<div>
            <iframe className="chooses-video" src={yt6}>
            </iframe>
            <TextinputA label={'Enter Video URL'} placeholder={'Enter Video URL'} onChange={(v) => onChangeYt6(v)} />
            <ButtonA name={'Update'} parentClass={'parent-flex-center'} onClick={handleYT6Update} />
          </div>)}
        </div>
        <br/>
      </div>

    </div>
  )
}

export default LoanPerBui