// ServicesCard.jsx
import React from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for type checking
import '../assets/css/comman/servicecard.css';
import ButtonA from '../atoms/ButtonA';
// import { Link } from 'react-router-dom';
// import { scrollToTop } from '../utils/scrollToTop';
import { HashLink } from 'react-router-hash-link';

const ServicesCard = ({ imageSrc, title, description,navigation,ButtonName,sectionId }) => {

    // const navigate = useNavigate();
    // console.log('Navigation Sectio INfd' , navigation+sectionId)


    return (
        <div className='services-main'>
            <div className="from-Box py-1">
                <img src={imageSrc} alt="img" className="img1" />
                <div className='text-line' />
                <h2 className='text-t1s'>{title}</h2>
                <p className='text-t2'>{description}</p>
                <HashLink smooth  to={navigation + sectionId} style={{textDecoration:`none`}} >
                    <ButtonA name={ButtonName} parentClass={`service-parent-flex-center`}></ButtonA>
                </HashLink>
            </div>
        </div>
    );
}

ServicesCard.propTypes = {
    imageSrc: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default ServicesCard;
