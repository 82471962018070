import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import "../../assets/css/comman/homeloan.css";
import LoanImage from '../../assets/images/Slider2.jpeg'
import Images1 from '../../assets/images/lona/eductionloan1.jpg';
import Images2 from '../../assets/images/lona/eductionaloan2.jpg';
import ButtonA from "../../atoms/ButtonA";
import TextinputA from '../../atoms/TextinputA';
import { Context } from '../../../App.js';
import { ytLinkModifier } from '../../utils/utils.tsx';
import { FETCH } from '../../services/Fetch.tsx';
import { Link } from "react-router-dom";
import { scrollToTop } from '../../utils/scrollToTop';
const LoanEduction = () => {
  const { editMode, setEditMode } = useContext(Context)
  // handleCOntroller STates 
  const [yt9, setYt9] = useState('https://www.youtube.com/embed/yydb_gBpEA0?si=ZrmTv17CzgOpxFV_');

  // changeControllers 
  const onChangeYt9 = (text) => {
    // console.log('Text is changing',text)
    setYt9(prev => { return text })
  }

  // handleControllers 
  const handleYT9Update = async () => {
    try {
      // console.log('YTLInk MOdifier',ytLinkModifier(yt9))
      setYt9(prev => {
        return ytLinkModifier(yt9)
      })

      let formData = {
        ytId: 'yt9',
        link: ytLinkModifier(yt9)
      }

      let { data, status } = await FETCH(
        'post',
        '/update-yt-link',
        '',
        formData
      )
      alert(data.message)
    } catch (error) {
      if (error) {
        // console.log('Error ***',error)
      }
    }
  }

  // preFetch Calls
  let yt9call = async () => {
    try {
      let { data, status } = await FETCH(
        'get',
        '/get-yt-link',
        'yt9'
      )
      return { data, status }
    } catch (error) {
      if (error) {
        // console.log('Error ***',error)
      }
    }
  }

  useEffect(() => {
    // get the value of yt9 
    yt9call().then(data => {
      if (data.status === 200) {
        setYt9(data.data.data.link)
      } else {
        setYt9('https://www.youtube.com/embed/yydb_gBpEA0?si=ZrmTv17CzgOpxFV_')
      }
    }).catch(
      error => {
        if (error) {
          // console.log('Error ***',error)
        }
      }
    )

  }, [])

  return (
    <div id="educationloan" className="container">
      <div className="edu-img-fluid loan-img-fluid">
        <h2></h2>
      </div>
      <p className="section-heading" style={{ margin: 20, textAlign: `center` }}>Education Loan</p>





      <p className="font-size at ap-para">An education loan is a financial aid provided by banks or financial institutions to students for funding their educational expenses. These loans typically cover tuition fees, books, and living expenses. Repayment is usually required after the completion of the education, and interest rates may vary based on the loan terms.</p>

      <div className="at home-criteria-part" style={{ lineHeight: `0.5` }}>
        <div className='section-inner-heading'>Eligibility Criteria: </div>
          <div className="font-size at ap-para">To qualify for an education loan, applicants typically need to demonstrate admission to a recognized course, provide collateral or a co-signer, and maintain a good academic record.</div></div>
     
      <div className="at home-criteria-part" style={{ lineHeight: `0.5` }}>
        <div className='section-inner-heading'>Our Offerings:</div>
          <div className="font-size at ap-para">Finasource Innovations facilitates education loans through Nationalized Banks, Private Banks, and NBFCs, ensuring diverse financial options for students, with competitive rates and flexible repayment terms.</div>
      </div>
      <Link to="/loanform" onClick={scrollToTop}><ButtonA name={`Apply Now`}></ButtonA></Link>
      <div className="chooses-video-main">

        {/*YT9*/}

        {!editMode ? (<iframe className="chooses-video" src={yt9}></iframe>) : (<div>
          <iframe className="chooses-video" src={yt9}>
          </iframe>
          <TextinputA label={'Enter Video URL'} placeholder={'Enter Video URL'} onChange={(v) => onChangeYt9(v)} />
          <ButtonA name={'Update'} parentClass={'parent-flex-center'} onClick={handleYT9Update} />
        </div>)}
      </div>
      <br/>

      
    
      </div>

  )
}

export default LoanEduction