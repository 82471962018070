import React, { useState, useEffect, useLayoutEffect, useContext, useRef } from 'react'
import ButtonA from "../../atoms/ButtonA";
import "../../assets/css/containers/homeloan1.css";
import TextinputA from '../../atoms/TextinputA';
import { Context } from '../../../App';
import { ytLinkModifier } from '../../utils/utils.tsx';
import { FETCH } from '../../services/Fetch.tsx'
import {  useLocation } from "react-router-dom";
import { scrollToTop } from '../../utils/scrollToTop';
import ListItem from '../../atoms/ListItem.js';
import Homelc from '../../assets/images/Home-Loan-Calculator.jpg'
import eligiC from '../../assets/images/Eligibility-cal.jpeg'
import { HashLink as Link } from 'react-router-hash-link';



const HomeLoan1 = () => {

  const { editMode, setEditMode } = useContext(Context)
  const location = useLocation();



  // handleCOntroller STates 
  const [yt2, setYt2] = useState("");
  const [yt3, setYt3] = useState("");

  // changeCOntrollers 
  const onChangeYt2 = (text) => {
    // console.log('Text is changing', text)
    setYt2(prev => { return text })
  }
  const onChangeYt3 = (text) => {
    // console.log('Text is changing', text)
    setYt3(prev => { return text })
  }


  // handleCOntrollers 
  const handleYT2Update = async (e) => {
    e.preventDefault()
    setYt2(prev => {
      return ytLinkModifier(yt2)
    })
    // Update your db
    let formData = {
      ytId: 'yt2',
      link: ytLinkModifier(yt2)
    }

    try {
      let { data, status } = await FETCH(
        'post',
        '/update-yt-link',
        '',
        formData
      )
      alert(data.message)
    } catch (error) {
      if (error) {
        // console.log('Error ***', error)
      }
    }

  }

  const handleYT3Update = async () => {
    // console.log('12321', yt3)
    // console.log('YTLInk MOdifier', ytLinkModifier(yt3))
    setYt3(prev => {
      return ytLinkModifier(yt3)
    })
    // Update your db
    let formData = {
      ytId: 'yt3',
      link: ytLinkModifier(yt3)
    }
    try {
      let { data, status } = await FETCH(
        'post',
        '/update-yt-link',
        '',
        formData
      )
      alert(data.message)

    } catch (error) {
      if (error) {
        // console.log('Error ***', error)
      }
    }
  }



  // preFetch Calls
  let yt2call = async () => {
    try {
      let { data, status } = await FETCH(
        'get',
        '/get-yt-link',
        'yt2'
      )
      return { data, status }
    } catch (error) {
      if (error) {
        // console.log('Error ***', error)
      }
    }
  }

  let yt3call = async () => {
    try {
      let { data, status } = await FETCH(
        'get',
        '/get-yt-link',
        'yt3'
      )
      return { data, status }
    } catch (error) {
      if (error) {
        // console.log('Error ***', error)
      }
    }
  }


  useEffect(() => {
    // yt2 
    yt2call().then(data => {
      if (data.status === 200) {
        setYt2(data.data.data.link)
      } else {
        setYt2('https://www.youtube.com/embed/YMjTktGtVCk?si=UJVuIkk0uqC-7jc4')
      }
    }).catch(error => {
      if (error) {
        // console.log('Error ***', error)
      }
    })

    // yt3 
    yt3call().then(data => {
      if (data.status === 200) {
        setYt3(data.data.data.link)
      } else {
        setYt3('https://www.youtube.com/embed/YMjTktGtVCk?si=UJVuIkk0uqC-7jc4')
      }
    }).catch(error => {
      if (error) {
        // console.log('Error ***', error)
      }
    })


  }, [])

  return (
    <div className="homeloan-container">

      <div className="homeloan-container-main" style={{ width: `100vw`, paddingTop: 40 }}>
        <p className="section-heading">Calculators</p>

        <div className="homeloan-box-main">
          <div className="homeloan-boxs">
            <img src={Homelc} className="homeloan-img-2" alt={"logo"} />
            <p className="homeloan-text">Home Loan Calculator</p>
            <Link to="/homeLoancalculator" onClick={scrollToTop} style={{ textDecoration: `none` }}>
              <ButtonA name={'Calculate'}
                parentClass={'parent-flex-center'}></ButtonA>
            </Link>
          </div>

          <div className="homeloan-boxs">
            <img src={eligiC} className="homeloan-img-2" alt={"logo"} />
            <p className="homeloan-text">Eligibility Calculator</p>
            <Link to="/eligibilitycalculator" onClick={scrollToTop} style={{ textDecoration: `none` }}>
              <ButtonA name={'Calculate'}
                parentClass={'parent-flex-center'}></ButtonA>
            </Link>
          </div>
        </div>
      </div>

      <div   id="homeLoan" className="homeloan-sections1 bg-section-color" style={{ width: `100vw`, paddingTop: 40 }}>
        <p className="section-heading">Loans That We Provide</p>

        <div className="homeloan-vh-content">

          <div className="homeloan-heding-main">
            <p className="section-inner-heading" style={{ fontSize: `2.5rem`, fontWeight: `700` }}>Home Loan</p>
            <p className="font-size at ap-para">A home loan, also known as a mortgage, is a financial arrangement where a borrower obtains funds from a lender to purchase or refinance a residential property. The residential property can be a property under construction, ready to use property, a resale property or a land with construction plan. The borrower repays the loan over time, typically with interest. The property serves as collateral, and failure to repay can result in foreclosure.</p>


          </div>
          <div className="at home-criteria-part" style={{ lineHeight: `0.5` }}>
            <div className='section-inner-heading'>Eligibility Criteria:</div>
            <div className="font-size at ap-para">Individuals, including salaried and self-employed individuals, can avail home loans. Lenders assess factors like income, credit history, and property value. Eligibility varies, but generally, anyone with a stable income and good credit can apply. Some government schemes also facilitate home loans for specific demographics, promoting wider homeownership.</div>
          </div>

          <div className="at home-criteria-part" style={{ lineHeight: `0.5` }}>
            <div className='section-inner-heading'>Our Offerings:</div>
              <div className="font-size at ap-para">Finasource Innovations, your trusted partner in realizing homeownership dreams, specialize in facilitating seamless home loans through collaboration with Nationalized and Private Banks, as well as NBFCs. Our dedicated professionals provide personalized, door-step services, ensuring a hassle-free experience. At Finasource, we prioritize transparency, competitive interest rates, and tailored solutions. Whether you're a first-time homebuyer or looking to refinance, or balance transfer of your existing home loan, our expert guidance and efficient processes make securing a home loan straightforward. Explore the possibilities with Finasource Innovations, where innovation meets excellence in home financing. Your dream home is just a step away with our reliable and professional services.</div>
          </div>

          <div className="section-inner-heading" style={{ textAlign: 'left' }}>Our Unique Features:</div>

          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`1. Home Loan Linked Saving Account `}
            bodyMessage={`A Home Loan-Linked Saving Account is a financial product where the borrower's savings account is linked to their home loan. The balance in this account is considered for interest calculation, helping reduce the loan principal faster. It offers potential interest savings and accelerates the repayment process, benefiting the borrower.`}
          />
          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`2. Competitive Interest Rates `}
            bodyMessage={`Empower savings, ensuring affordability and financial well-being for you.`}
          />
          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`3. Transparency in Operations `}
            bodyMessage={` Exemplary transparency defines our operations, fostering trust and client confidence.`}
          />
          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`4. Doorstep Services `}
            bodyMessage={` Convenient doorstep services for hassle-free solutions right at your home`}

          />

          <div className="chooses-video-main" style={{ marginBottom: `20px`, marginTop: `40px` }}>

            {/*YT2*/}

            {!editMode ? (<iframe className="chooses-video" src={yt2}></iframe>) : (<div>
              <iframe className="chooses-video" src={yt2}>
              </iframe>
              <TextinputA label={'Enter Video URL'} placeholder={'Enter Video URL'} onChange={(v) => onChangeYt2(v)} />
              <ButtonA name={'Update'} parentClass={'parent-flex-center'} onClick={handleYT2Update} />
            </div>)}
          </div>

        </div>

        <div id="loanAgainstproperty" className="homeloan-vh-content hvcontent" style={{ backgroundColor: `white`, width: `100vw`, paddingTop: `40px` }}>
          <div className="homeloan-heding-main">
            <p className="section-inner-heading" style={{ fontSize: `2.5rem`, fontWeight: `700` }}>Loan Against Property</p>
            <p className="font-size at ap-para">A Loan Against Property (LAP) is a secured loan where individuals can leverage their owned property, be it residential or commercial, as collateral to secure funds. The loan amount is determined by the property's value. It provides a cost-effective option for meeting diverse financial needs, with longer tenures and competitive interest rates.</p>
            <p className="font-size at ap-para">Loan Against Property (LAP) eligibility aligns with home loans, requiring stable income and good credit. The property's value influences the loan amount. While interest rates are higher than home loans, they tend to be lower than personal and business loans. LAP provides a middle-ground option for substantial financial requirements.</p>
          </div>

          <div className="chooses-video-main">

            {/*YT3*/}

            {!editMode ? (<iframe className="chooses-video" src={yt3}></iframe>) : (<div>
              <iframe className="chooses-video" src={yt3}>
              </iframe>
              <TextinputA label={'Enter Video URL'} placeholder={'Enter Video URL'} onChange={(v) => onChangeYt3(v)} />
              <ButtonA name={'Update'} parentClass={'parent-flex-center'} onClick={handleYT3Update} />
            </div>)}
          </div>
        </div>


      </div>
      <br/>
      <Link to="/loanform" onClick={scrollToTop}><ButtonA name={`Apply Now`}></ButtonA></Link>
    </div>
  )
}

export default HomeLoan1